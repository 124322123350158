import { FormFieldProps } from "../../../components/forms/FormField";
import { createStyles, makeStyles } from "@mui/styles";
import { Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import Form from "../../../components/forms/Form";
import { v4 as uuid } from "uuid";
import { Storage } from "@aws-amplify/storage";
import { useParams, useHistory } from "react-router-dom";
import API from "@aws-amplify/api";
import { createDownloadable, updateDownloadable } from "../../../graphql/mutations";
import { getDownloadable } from "../../../graphql/queries";

const fields: FormFieldProps[] = [
    {
        name: "name",
        label: "Name of this file",
        type: "text",
        required: true,
    }, {
        name: "url",
        label: "Select a file",
        type: "file",
        required: true,
    }, {
        name: "index",
        label: "Index",
        type: "number",
    }
]

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
        },
    }),
);

export interface FormDownloadable {
    name: string;
    url: string | File;
    index?: number;
}

const DownloadableForm = (): JSX.Element => {
    const [downloadable, setDownloadable] = React.useState({});
    const classes = useStyle();
    const { courseId, moduleId, downloadableId } =
    useParams<{ courseId: string; moduleId: string; downloadableId: string }>();
    const history = useHistory();

    React.useEffect(()=> {
        async function syncDonwloadable() {
            const downloadable = await API.graphql({
                query: getDownloadable,
                variables: { id: downloadableId },
            });
            setDownloadable((downloadable as any).data.getDownloadable);
        }
        if(downloadableId) {
            syncDonwloadable();
        }
    }, [downloadableId]);

    const onSubmit = async (value: FormDownloadable) => {
        const file = value.url;
        let url;
        if (file && file instanceof File) {
            const extension = file.name.split(".").pop();
            const fileName = uuid() + "." + extension;
            const result = await Storage.put(fileName, file, {
                level: "public",
            });
            url = (result as { key: string }).key;
        } else {
            url = file;
        }
        const input = {
            url,
            name: value.name,
            index: value.index,
        }
        if(!downloadableId) {
            const result = await API.graphql({
                query: createDownloadable,
                variables: { input: { ...input, moduleId }},
            });
            history.goBack();
        } else {
            const result = await API.graphql({
                query: updateDownloadable,
                variables: { input: { id: downloadableId, ...input, }}
            });
            history.push(`/courses/${courseId}/modules/${moduleId}/tools/${downloadableId}`)
        }
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="h5">Module</Typography>
            <Form fields={fields} onSubmit={onSubmit} value={downloadable} />
        </Paper>
    );
}

export default DownloadableForm;