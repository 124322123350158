import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import API, { GraphQLResult } from "@aws-amplify/api";
import * as React from "react";
import Form from "../../../components/forms/Form";
import * as mutations from "../../../graphql/mutations";
import { ListProgramsQuery } from "../../../API";
import { listCourses, listPrograms } from "../../../graphql/queries";
import { FormFieldProps } from "../../../components/forms/FormField";
import client from "../../../util/apiClient";

interface EnrollmentFormProps {
  userId: string;
  open: boolean;
  onClose: () => void;
}

const EnrollmentFormDialog = (props: EnrollmentFormProps): JSX.Element => {
  const { open, onClose, userId } = props;
  const [programs, setPrograms] = React.useState<any[]>([]);

  React.useEffect(() => {
    async function fetchPrograms() {
      const result = await API.graphql({
        query: listPrograms,
      });
      setPrograms(
        (result as any).data.listPrograms.items,
      );
    }
    fetchPrograms();
  }, [userId]);

  const fields: FormFieldProps[] = [
    {
      name: "programId",
      label: "Program",
      type: "select",
      required: true,
      options: programs.map((c) => ({ label: c.name, value: c.id })),
    },
  ];

  const onSubmit = async (value) => {
    const result = await client.post(`admin/users/${props.userId}/subscribe`, {
      programId: value.programId,
    });
    onClose();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onBackdropClick={onClose}
    >
      <DialogTitle>Program enrollment</DialogTitle>
      <DialogContent>
        <Form fields={fields} onSubmit={onSubmit} value={{}} />
      </DialogContent>
    </Dialog>
  );
};

export default EnrollmentFormDialog;
