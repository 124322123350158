import { Grid } from "@mui/material";
import * as React from "react";

export interface DataField {
  key: string;
  label: string;
  renderFunction?: (value) => JSX.Element | string;
}

export interface SingleProps {
  data: { [key: string]: any };
  fields: DataField[];
}

const Single = (props: SingleProps): JSX.Element => {
  const { data, fields } = props;

  const value = (field: DataField): JSX.Element => {
    if(!data) {
      return <div/>
    }
    if (field.renderFunction) {
      const value = field.renderFunction(data[field.key]);
      if (typeof value === "string") {
        return <div>{value}</div>;
      }
      return value;
    }
    return <div>{data[field.key]}</div>;
  };

  return (
    <Grid container>
      {fields.map((field) => (
        <React.Fragment key={field.key}>
          <Grid item xs={5} md={3}>
            {field.label}
          </Grid>
          <Grid item xs={7} md={9} style={{ whiteSpace: "pre-wrap" }}>
            {value(field)}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default Single;
