import API from "@aws-amplify/api";
import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import Form from "../../../components/forms/Form";
import { FormFieldProps } from "../../../components/forms/FormField";
import {
  createQuestion,
  updateQuestion,
  createOption,
  updateOption,
  deleteOption,
} from "../../../graphql/mutations";
import { useParams } from "react-router";
import { getQuestion } from "../../../graphql/queries";
import { useHistory } from "react-router-dom";

const fields: FormFieldProps[] = [
  {
    name: "type",
    label: "Question type",
    type: "select",
    required: true,
    options: [
      { label: "Short text", value: "SHORT_TEXT" },
      { label: "Long text", value: "LONG_TEXT" },
      { label: "Single choice", value: "SINGLE_CHOICE" },
      { label: "Mulitple choice", value: "MULTIPLE_CHOICE" },
    ],
  },
  {
    name: "introduction",
    label: "Question introduction",
    type: "textarea",
    required: false,
  },
  {
    name: "question",
    label: "Question",
    type: "text",
    required: true,
  },
  {
    name: "index",
    label: "Sorting order",
    type: "number",
    required: true,
  },
  {
    name: "options",
    label: "Options",
    type: "array",
    fields: [
      {
        name: "label",
        label: "Label",
        type: "text",
      },
      {
        name: 'index',
        label: 'Index',
        type: 'number',
      }
    ],
  },
];

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  }),
);

enum QuestionType {
  SHORT_TEXT,
  LONG_TEXT,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
}

export interface FormQuestion {
  options: {
    label: string | number;
    id?: string;
    index?: number;
  }[];
  question: string;
  introduction?: string;
  index: number;
  type: QuestionType;
}

const QuestionForm = (): JSX.Element => {
  const [question, setQuestion] = React.useState<
    FormQuestion | Record<string, never>
  >({});
  const history = useHistory();
  const classes = useStyle();
  const { courseId, moduleId, questionId } =
    useParams<{ courseId: string; moduleId: string; questionId: string }>();

  React.useEffect(() => {
    async function syncQuestion() {
      const result = await API.graphql({
        query: getQuestion,
        variables: { id: questionId },
      });
      const question = (result as any).data.getQuestion;
      setQuestion({
        ...question,
        options: question?.options?.items,
      });
    }
    if (questionId) {
      syncQuestion();
    }
  }, [questionId]);

  const onSubmit = async (value: FormQuestion) => {
    const { question: q, index, introduction, type, options = [] } = value;
    if (!questionId) {
      const result = await API.graphql({
        query: createQuestion,
        variables: {
          input: {
            question: q,
            introduction,
            type,
            moduleId,
            index,
          },
        },
      });
      for (const option of options) {
        await API.graphql({
          query: createOption,
          variables: {
            input: {
              questionId: (result as any).data.createQuestion.id,
              label: option.label,
              index: option.index,
            },
          },
        });
      }
    } else {
      const input = {
        id: questionId,
        question: q,
        moduleId,
        introduction,
        index,
        type,
      };
      const result = await API.graphql({
        query: updateQuestion,
        variables: { input },
      });
      for (const option of options) {
        if (!option.id) {
          await API.graphql({
            query: createOption,
            variables: {
              input: {
                questionId: questionId,
                label: option.label,
                index: option.index,
              },
            },
          });
        } else {
          const oldOption = question.options.find(
            (opt) => opt.id === option.id,
          );
          if (oldOption && (oldOption.label !== option.label || oldOption.index !== option.index)) {
            await API.graphql({
              query: updateOption,
              variables: {
                input: {
                  id: option.id,
                  label: option.label,
                  index: option.index,
                },
              },
            });
          }
        }
      }
      const removed = question.options.filter(
        (opt) => !options.find((x) => x.id === opt.id),
      );
      for (const option of removed) {
        await API.graphql({
          query: deleteOption,
          variables: {
            input: {
              id: option.id,
            },
          },
        });
      }
    }
    history.push(`/courses/${courseId}/modules/${moduleId}`);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">Question</Typography>
      <Form fields={fields} onSubmit={onSubmit} value={question} />
    </Paper>
  );
};

export default QuestionForm;
