import {
  TextField,
  Theme,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Avatar,
  Paper,
  Alert,
  LinearProgress,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";
import LockedOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestSignIn } from "../store/auth/actions";
import {
  authErrorSelector,
  isAuthenticatedSelector,
  isLoadingSelector,
} from "../store/auth/selectors";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    alert: {
      marginTop: "20px",
      width: "100%",
    },
  }),
);

const LoginScreen = (props) => {
  const classes = useStyle();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isLoading = useSelector(isLoadingSelector);
  const authError = useSelector(authErrorSelector);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const dispatch = useDispatch();

  const onSubmit: React.FormEventHandler = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(requestSignIn(username, password));
  };

  return (
    <section className={classes.main}>
      <Paper>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockedOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email address"
              name="email"
              autoComplete="email"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {!isLoading ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Sign In
              </Button>
            ) : (
              <LinearProgress />
            )}
          </form>
          {authError != null ? (
            <Alert severity="error" className={classes.alert}>
              {authError}
            </Alert>
          ) : null}
        </div>
      </Paper>
    </section>
  );
};

export default LoginScreen;
