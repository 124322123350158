import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
} from "./actionTypes";
import {
  SignInFailure,
  SignInRequest,
  SignInSuccess,
  SignOutFailure,
  SignOutRequest,
  SignOutSuccess,
} from "./types";

export const requestSignIn = (
  username: string,
  password: string,
): SignInRequest => ({
  type: SIGN_IN_REQUEST,
  payload: {
    username,
    password,
  },
});

export const signInSuccess = (): SignInSuccess => ({
  type: SIGN_IN_SUCCESS,
});

export const signInFailure = (error: string): SignInFailure => ({
  type: SIGN_IN_FAILURE,
  payload: {
    error,
  },
});

export const requestSignOut = (): SignOutRequest => ({
  type: SIGN_OUT_REQUEST,
});

export const signOutSuccess = (): SignOutSuccess => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error: string): SignOutFailure => ({
  type: SIGN_OUT_FAILURE,
  payload: {
    error,
  },
});
