import { createSelector } from "reselect";
import { AppState } from "../rootReducer";

const isAuthenticated = (state: AppState) => state.auth.authenticated;
const isLoading = (state: AppState) => state.auth.pending;
const authError = (state: AppState) => state.auth.error;

export const isAuthenticatedSelector = createSelector(
  isAuthenticated,
  (result) => result,
);

export const isLoadingSelector = createSelector(isLoading, (result) => result);

export const authErrorSelector = createSelector(authError, (result) => result);
