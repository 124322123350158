import { Card, Fab, Input, InputAdornment, Paper, TextField, Theme } from "@mui/material";
import API from "@aws-amplify/api";
import * as React from "react";
import DataTable, { Column } from "../../components/lists/DataTable";
import { adminListUsers } from "../../graphql/queries";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { makeStyles, createStyles } from "@mui/styles";
import { useDebounce } from "../../util/debounceHook";
import client from "../../util/apiClient";

type Value = {
  Name: string;
  Value?: string;
};

const profileFields: Column[] = [
  {
    field: 'firstName',
    headerName: 'First name',
  },
  {
    field: 'lastName',
    headerName: 'Last name',
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
  },
];

const fields: Column[] = [
  {
    field: 'Attributes',
    headerName: 'Email address',
    renderFn: (v) => v && v.find(a => a.Name === 'email').Value
  },
  {
    field: 'Attributes',
    headerName: 'First name',
    renderFn: (v) => v && v.find(a => a.Name === 'given_name')?.Value 
  },
  {
    field: 'Attributes',
    headerName: 'Last name',
    renderFn: (v) => v && v.find(a => a.Name === 'family_name')?.Value 
  },
  {
    field: 'UserCreateDate',
    headerName: 'Created at'
  },
  {
    field: 'Attributes',
    headerName: 'Imported',
    renderFn: (v) => v && v.find(a => a.Name === 'custom:migrated')?.Value,
  }
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '16px',
      marginBottom: '32px'
    },
    searchBar: {
      width: '50%',
      maxWidth: '400px',
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
  }),
);

const UserList = (props): JSX.Element => {
  const [data, setData] = React.useState([]);
  const [search, setSearch] = React.useState<string>("");
  const debouncedSearch = useDebounce<string | undefined>(search, 750);
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncUsers() {
      const result = await client.get('admin/users', {
        search: search ?? ''
      });
      setData((result as any).users);
    }
    syncUsers();
  }, [debouncedSearch]);

  const onFabClick = () => {
    history.push("/users/add");
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <TextField
        className={classes.searchBar}
          placeholder="Zoek een email adres"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </Paper>
      <DataTable
        columns={fields}
        data={data}
        onRowClick={(row) => history.push(`/users/${encodeURIComponent(row.Attributes.find(a => a.Name === 'sub').Value)}`)}
        idProp={"Username"}
      />

      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={onFabClick}
      >
        <AddIcon />
      </Fab>
    </React.Fragment>
  );
};

export default UserList;
