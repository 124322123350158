import { Paper, Theme } from "@mui/material";
import API from "@aws-amplify/api";
import * as React from "react";
import DataTable, { Column } from "../../../components/lists/DataTable";
import { listQuestions } from "../../../graphql/queries";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { fetchAll } from "../../../util/apiHelpers";
import { ellipsis } from "../../../util/stringHelpers";

const fields: Column[] = [
  { field: "index", headerName: "Index" },
  { field: "type", headerName: "Type" },
  { field: "question", headerName: "Question" },
  { field: "introduction", headerName: "Introduction", renderFn: (val) => ellipsis(val, 100) },
  { field: "createdAt", headerName: "Created at" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "500px",
      position: "relative",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

export interface QuestionListProps {
  moduleId?: string;
  courseId?: string;
}

const QuestionList = (props: QuestionListProps): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = React.useState<any[]>([]);
  const history = useHistory();
  const { courseId, moduleId } = props;

  React.useEffect(() => {
    async function syncQuestions() {

      setData(await fetchAll('listQuestions', {
          query: listQuestions,
          variables: { filter: { moduleId: { eq: moduleId } }},
        }));

    }
    syncQuestions();
  }, [moduleId]);

  const onRowClick = (row) => {
    return history.push(
      `/courses/${courseId}/modules/${moduleId}/questions/${row.id}`,
    );
  };

  return (
    <DataTable
      columns={fields}
      data={data.sort((a, b) => a.index - b.index)}
      onRowClick={onRowClick}
    />
  );
};

export default QuestionList;
