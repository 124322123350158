/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminGetUser = /* GraphQL */ `
  query AdminGetUser($username: String) {
    adminGetUser(username: $username) {
      Attributes {
        Name
        Value
      }
      Enabled
      MFAOptions {
        AttributeName
        DeliveryMedium
      }
      PreferredMfaSetting
      UserCreateDate
      UserLastModifiedDate
      UserMFASettingList
      UserStatus
      Username
    }
  }
`;
export const adminListGroups = /* GraphQL */ `
  query AdminListGroups {
    adminListGroups {
      CreationDate
      Description
      GroupName
      LastMOdifiedDate
      Precedence
    }
  }
`;
export const adminListUsers = /* GraphQL */ `
  query AdminListUsers($email: String) {
    adminListUsers(email: $email) {
      Attributes {
        Name
        Value
      }
      Enabled
      MFAOptions {
        AttributeName
        DeliveryMedium
      }
      PreferredMfaSetting
      UserCreateDate
      UserLastModifiedDate
      UserMFASettingList
      UserStatus
      Username
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      answer
      createdAt
      id
      moduleProgressId
      owner
      questionId
      updatedAt
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      accessGroup
      coverImage
      createdAt
      description
      id
      modules {
        items {
          courseId
          coverImage
          createdAt
          delayNumber
          delayUOM
          description
          id
          index
          name
          updatedAt
          videoUrl
        }
        nextToken
      }
      name
      updatedAt
    }
  }
`;
export const getDownloadable = /* GraphQL */ `
  query GetDownloadable($id: ID!) {
    getDownloadable(id: $id) {
      createdAt
      id
      index
      moduleId
      name
      owner
      updatedAt
      url
    }
  }
`;
export const getEnrollment = /* GraphQL */ `
  query GetEnrollment($id: ID!) {
    getEnrollment(id: $id) {
      completedAt
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      enrolledAt
      id
      moduleSchedule {
        items {
          availableAt
          completedAt
          createdAt
          enrollmentId
          id
          moduleId
          owner
          startedAt
          updatedAt
        }
        nextToken
      }
      owner
      startedAt
      updatedAt
    }
  }
`;
export const getIngredient = /* GraphQL */ `
  query GetIngredient($id: ID!) {
    getIngredient(id: $id) {
      amount
      createdAt
      id
      product {
        createdAt
        id
        ingredients {
          nextToken
        }
        name
        namePlural
        updatedAt
      }
      productId
      recipe {
        createdAt
        date
        id
        imageUrl
        ingredients {
          nextToken
        }
        instructionVideoUrl
        name
        preparationMinutes
        state
        steps {
          nextToken
        }
        totalMinutes
        updatedAt
      }
      recipeId
      unit {
        createdAt
        id
        name
        sign
        updatedAt
      }
      updatedAt
    }
  }
`;
export const getMeasurement = /* GraphQL */ `
  query GetMeasurement($id: ID!) {
    getMeasurement(id: $id) {
      createdAt
      date
      id
      note
      owner
      type
      updatedAt
      weight
    }
  }
`;
export const getMeasurementsByDate = /* GraphQL */ `
  query GetMeasurementsByDate(
    $date: ModelStringKeyConditionInput
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $type: String
  ) {
    getMeasurementsByDate(
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      type: $type
    ) {
      items {
        createdAt
        date
        id
        note
        owner
        type
        updatedAt
        weight
      }
      nextToken
    }
  }
`;
export const getMembership = /* GraphQL */ `
  query GetMembership($id: ID!) {
    getMembership(id: $id) {
      cognitoId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const getModule = /* GraphQL */ `
  query GetModule($id: ID!) {
    getModule(id: $id) {
      assignments {
        items {
          createdAt
          id
          index
          introduction
          moduleId
          question
          type
          updatedAt
        }
        nextToken
      }
      courseId
      coverImage
      createdAt
      delayNumber
      delayUOM
      description
      id
      index
      name
      tools {
        items {
          createdAt
          id
          index
          moduleId
          name
          owner
          updatedAt
          url
        }
        nextToken
      }
      updatedAt
      videoUrl
    }
  }
`;
export const getModuleProgress = /* GraphQL */ `
  query GetModuleProgress($id: ID!) {
    getModuleProgress(id: $id) {
      availableAt
      completedAt
      createdAt
      enrollmentId
      id
      module {
        assignments {
          nextToken
        }
        courseId
        coverImage
        createdAt
        delayNumber
        delayUOM
        description
        id
        index
        name
        tools {
          nextToken
        }
        updatedAt
        videoUrl
      }
      moduleId
      owner
      startedAt
      updatedAt
      workbook {
        items {
          answer
          createdAt
          id
          moduleProgressId
          owner
          questionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      createdAt
      id
      index
      label
      questionId
      updatedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      createdAt
      id
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      name
      namePlural
      updatedAt
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      birthDate
      cognitoId
      createdAt
      currentCourse {
        items {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        nextToken
      }
      currentCourseId
      email
      firstName
      id
      lastName
      length
      targetWeight
      updatedAt
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      courses {
        items {
          courseId
          createdAt
          id
          programId
          updatedAt
        }
        nextToken
      }
      createdAt
      description
      id
      imageUrl
      isForSale
      name
      price
      productStoreId
      productUrl
      updatedAt
    }
  }
`;
export const getProgramCourse = /* GraphQL */ `
  query GetProgramCourse($id: ID!) {
    getProgramCourse(id: $id) {
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      answer {
        answer
        createdAt
        id
        moduleProgressId
        owner
        questionId
        updatedAt
      }
      createdAt
      id
      index
      introduction
      moduleId
      options {
        items {
          createdAt
          id
          index
          label
          questionId
          updatedAt
        }
        nextToken
      }
      question
      type
      updatedAt
    }
  }
`;
export const getRecipe = /* GraphQL */ `
  query GetRecipe($id: ID!) {
    getRecipe(id: $id) {
      createdAt
      date
      id
      imageUrl
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      instructionVideoUrl
      name
      preparationMinutes
      state
      steps {
        items {
          createdAt
          id
          index
          instructionText
          recipeId
          updatedAt
        }
        nextToken
      }
      totalMinutes
      updatedAt
    }
  }
`;
export const getRecipeStep = /* GraphQL */ `
  query GetRecipeStep($id: ID!) {
    getRecipeStep(id: $id) {
      createdAt
      id
      index
      instructionText
      recipeId
      updatedAt
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      createdAt
      id
      name
      sign
      updatedAt
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        answer
        createdAt
        id
        moduleProgressId
        owner
        questionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDownloadables = /* GraphQL */ `
  query ListDownloadables(
    $filter: ModelDownloadableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDownloadables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        index
        moduleId
        name
        owner
        updatedAt
        url
      }
      nextToken
    }
  }
`;
export const listEnrollments = /* GraphQL */ `
  query ListEnrollments(
    $filter: ModelEnrollmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        completedAt
        course {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        courseId
        createdAt
        enrolledAt
        id
        moduleSchedule {
          nextToken
        }
        owner
        startedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listIngredients = /* GraphQL */ `
  query ListIngredients(
    $filter: ModelIngredientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIngredients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        amount
        createdAt
        id
        product {
          createdAt
          id
          name
          namePlural
          updatedAt
        }
        productId
        recipe {
          createdAt
          date
          id
          imageUrl
          instructionVideoUrl
          name
          preparationMinutes
          state
          totalMinutes
          updatedAt
        }
        recipeId
        unit {
          createdAt
          id
          name
          sign
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMeasurements = /* GraphQL */ `
  query ListMeasurements(
    $filter: ModelMeasurementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        date
        id
        note
        owner
        type
        updatedAt
        weight
      }
      nextToken
    }
  }
`;
export const listMemberships = /* GraphQL */ `
  query ListMemberships(
    $filter: ModelMembershipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMemberships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        cognitoId
        createdAt
        id
        program {
          createdAt
          description
          id
          imageUrl
          isForSale
          name
          price
          productStoreId
          productUrl
          updatedAt
        }
        programId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listModuleProgresses = /* GraphQL */ `
  query ListModuleProgresses(
    $filter: ModelModuleProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        availableAt
        completedAt
        createdAt
        enrollmentId
        id
        module {
          courseId
          coverImage
          createdAt
          delayNumber
          delayUOM
          description
          id
          index
          name
          updatedAt
          videoUrl
        }
        moduleId
        owner
        startedAt
        updatedAt
        workbook {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listModules = /* GraphQL */ `
  query ListModules(
    $filter: ModelModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        assignments {
          nextToken
        }
        courseId
        coverImage
        createdAt
        delayNumber
        delayUOM
        description
        id
        index
        name
        tools {
          nextToken
        }
        updatedAt
        videoUrl
      }
      nextToken
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        index
        label
        questionId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        ingredients {
          nextToken
        }
        name
        namePlural
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        birthDate
        cognitoId
        createdAt
        currentCourse {
          nextToken
        }
        currentCourseId
        email
        firstName
        id
        lastName
        length
        targetWeight
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProgramCourses = /* GraphQL */ `
  query ListProgramCourses(
    $filter: ModelProgramCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        course {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        courseId
        createdAt
        id
        program {
          createdAt
          description
          id
          imageUrl
          isForSale
          name
          price
          productStoreId
          productUrl
          updatedAt
        }
        programId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        answer {
          answer
          createdAt
          id
          moduleProgressId
          owner
          questionId
          updatedAt
        }
        createdAt
        id
        index
        introduction
        moduleId
        options {
          nextToken
        }
        question
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRecipeSteps = /* GraphQL */ `
  query ListRecipeSteps(
    $filter: ModelRecipeStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipeSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        index
        instructionText
        recipeId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRecipes = /* GraphQL */ `
  query ListRecipes(
    $filter: ModelRecipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        date
        id
        imageUrl
        ingredients {
          nextToken
        }
        instructionVideoUrl
        name
        preparationMinutes
        state
        steps {
          nextToken
        }
        totalMinutes
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        name
        sign
        updatedAt
      }
      nextToken
    }
  }
`;
