import { CognitoUser } from "@aws-amplify/auth";
import  { Auth } from "aws-amplify";

const BASE_URL = 'https://r8piskm9jj.execute-api.eu-west-1.amazonaws.com/dev/';

export class APIClient {
    constructor() {

    }

    private async idToken() {
        const session = await Auth.currentSession()
        const token = await session.getIdToken();
        return token.getJwtToken();
    }

    async get<T>(path: string, queryParams?: Record<string, string>): Promise<T> {
        const idToken = await this.idToken();
        const result = await fetch(BASE_URL + path + (queryParams ? "?" + new URLSearchParams(queryParams) : ""), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        return result.json();
    }

    async post<T>(path: string, body: any): Promise<T> {
        const idToken = await this.idToken();
        const result = await fetch(BASE_URL + path, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(body),
        });
        return result.json();
    }

    async put<T>(path: string, body: any): Promise<T> {
        const idToken = await this.idToken();
        const result = await fetch(BASE_URL + path, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(body),
        });
        return result.json();
    }
}

const client = new APIClient();

export default client;