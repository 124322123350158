import API from "@aws-amplify/api";
import { Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import Single, { DataField } from "../../../components/Single";
import { getQuestion } from "../../../graphql/queries";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteQuestion } from "../../../graphql/mutations";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Header from "../../../components/Header";

const fields: DataField[] = [
  { key: "question", label: "Question" },
  { key: "index", label: "Question sort order" },
  { key: "introduction", label: "Question introduction" },
  { key: "type", label: "Question Type" },
  {
    key: "options",
    label: "Options",
    renderFunction: (opts) => {
      if (opts) {
        return opts.map((opt) => (
          <Single
            key={opt.id}
            data={opt}
            fields={[{ key: "label", label: "Optie:" }]}
          />
        ));
      }
    },
  },
];

interface ParamsType {
  courseId: string;
  moduleId: string;
  questionId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const QuestionSingle = (): JSX.Element => {
  const [question, setQuestion] = React.useState({});
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const { courseId, moduleId, questionId } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncQuestion() {
      const course = await API.graphql({
        query: getQuestion,
        variables: { id: questionId },
      });
      const question = (course as any).data.getQuestion;
      setQuestion({
        ...question,
        options: question.options.items,
      });
    }
    syncQuestion();
  }, [questionId]);

  const onEditQuestion = () => {
    history.push(
      `/courses/${courseId}/modules/${moduleId}/questions/${questionId}/edit`,
    );
  };

  const handleDelete = async () => {
    await API.graphql({
      query: deleteQuestion,
      variables: { input: { id: questionId } },
    });
    history.push(`/courses/${courseId}/modules/${moduleId}`);
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="Question"
          actions={[
            {
              name: "Edit question",
              icon: <EditIcon />,
              onClick: onEditQuestion,
            },
            {
              name: "Delete question",
              icon: <DeleteIcon />,
              onClick: onDeleteClick,
            },
          ]}
        />
        <Single data={question} fields={fields} />
      </Paper>
      <ConfirmationDialog
        title="Are you sure"
        content="You're about to delete a question, this cannot be undone. Are you sure?"
        onConfirm={handleDelete}
        open={isOpen}
        onReject={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default QuestionSingle;
