import { KeyboardArrowDown, KeyboardArrowUp, MoreVert } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import * as React from "react";

export interface Column {
  field: string;
  headerName: string;
  renderFn?: (value) => JSX.Element | string | undefined;
}

export interface DataTableProps {
  columns: Column[];
  data: any[];
  onRowClick?: (value: any) => void;
  idProp?: string;
  ExpandComponent?: React.ElementType;
  rowActions?: RowAction[];
}

const useStyles = makeStyles(() => createStyles({
  thead: {
    fontWeight: "bold",
  },
  hoverRow: {
    cursor: "pointer",
  },
}));

interface RowProps {
  idProp?: string;
  row: object;
  columns: Column[];
  onClick?: (value: any) => void
  onMenuClick?: (value: any) => void;
  sx?: any;
}

interface RowAction {
  label: string;
  onClick?: (value: any) => void;
}

const Row = (props: RowProps) => {
  const { row } = props;
  const classes = useStyles();

  const getValue = (row, column: Column) => {
    const field = column.field;
    const path = field.split(".");
    const value = path.reduce((prev, cur) => prev ? prev[cur] : undefined, row);

    if (column.renderFn) {
      return column.renderFn(value);
    }
    return value;
  };

  const getKey = (row) => {
    if (!!props.idProp) {
      return row[props.idProp];
    }
    return row.id;
  };

  return (<TableRow
    sx={props.sx}
    className={classes.hoverRow}
    hover
    key={getKey(row)}
    onClick={() => props.onClick && props.onClick(row)}
  >
    {props.columns.map((col, idx) => (
      <TableCell key={col.field + idx}>{getValue(row, col)}</TableCell>
    ))}
    {props.onMenuClick ?
      <TableCell key="menu">
        <IconButton onClick={props.onMenuClick}>
          <MoreVert />
        </IconButton>
      </TableCell> : null}
  </TableRow>);
}

const ExpandableRow = (props: RowProps & {
  ExpandComponent: React.ElementType;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Row
        sx={{ '& > *': { borderBottom: 'unset' } }}
        columns={[
          {
            field: "expand",
            headerName: "",
            renderFn: () => <IconButton onClick={() => setOpen((val) => !val)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          },
          ...props.columns]}
        row={{ ...props.row }}
        idProp={props.idProp}
        onClick={() => props.onClick && props.onClick(props.row)} />
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={props.columns.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <props.ExpandComponent data={props.row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const DataTable = (props: DataTableProps): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuContextObj, setMenuContextObj] = React.useState<any>(null);

  const onMenuClick = (row) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuContextObj(row);
      setAnchorEl(event.currentTarget);
    }
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (action: RowAction) => {
    if(action.onClick){
      action.onClick(menuContextObj);
    }
    onMenuClose();
  }

  const cols: Column[] = [
    props.ExpandComponent ? { field: "expand", headerName: "" } : undefined,
    ...props.columns,
    props.rowActions ? { field: "id", headerName: "" } : undefined,
  ].filter(i => i);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {cols.map((col, idx) => (
              <TableCell key={col.field + idx} component="th" className={classes.thead}>
                {col.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            props.ExpandComponent ?
              <ExpandableRow
                row={row}
                columns={props.columns}
                idProp={props.idProp}
                ExpandComponent={props.ExpandComponent} />
              : <Row
                row={row}
                columns={props.columns}
                idProp={props.idProp}
                onClick={props.onRowClick}
                onMenuClick={props.rowActions ? onMenuClick(row) : undefined}
              />
          ))}
          {props.rowActions ? <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onMenuClose}>
            {props.rowActions?.map(action => (
              <MenuItem key={action.label} onClick={() => onMenuItemClick(action)}>{action.label}</MenuItem>
            ))}
        </Menu> : null}
      </TableBody>
    </Table>
    </TableContainer >
  );
};

export default DataTable;
