import { Alert, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import API, { GraphQLResult } from "@aws-amplify/api";
import * as React from "react";
import Form from "../../components/forms/Form";
import { FormFieldProps } from "../../components/forms/FormField";
import { adminCreateUser } from "../../graphql/mutations";
import { useHistory } from "react-router";
import client from "../../util/apiClient";

const fields: FormFieldProps[] = [
  {
    label: "Email address",
    name: "email",
    id: "email",
    type: "text",
    required: true,
  },
  {
    label: "First name",
    name: "firstName",
    id: "firstname",
    type: "text",
    required: true,
  },
  {
    label: "Last name",
    name: "lastName",
    id: "lastname",
    type: "text",
    required: true,
  },
];

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  }),
);

const UserForm = (props): JSX.Element => {
  const classes = useStyle();
  const history = useHistory();
  const [errors, setErrors] = React.useState<string[]>([]);

  const onSubmit = async (value: any) => {
    setErrors([]);
    try {
      const result = await client.post<{userId: string}>(`/admin/users`, value);
      if(result && result.userId) {
        const profile = await client.post(`admin/users/${result.userId}/profile`, value);
      }

      // const result = await API.graphql({
      //   query: adminCreateUser,
      //   variables: { ...value },
      // });
      history.push("/users");
    } catch (ex) {
      setErrors(['Could not create user']);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">User</Typography>
      <Form fields={fields} onSubmit={onSubmit} value={{}} />
      {errors.map((err) => (
        <Alert key={err} severity="warning">
          {err}
        </Alert>
      ))}
    </Paper>
  );
};

export default UserForm;
