import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as React from "react";

interface ConfirmationDialogProps {
  title: string;
  content: string;
  open: boolean;
  onConfirm?: () => void;
  onReject?: () => void;
}

const ConfirmationDialog = (props: ConfirmationDialogProps): JSX.Element => {
  const { open, title, content, onConfirm, onReject } = props;

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onBackdropClick={onReject}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onReject}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
