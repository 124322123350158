import { Storage } from "@aws-amplify/storage";
import API from "@aws-amplify/api";
import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import Form from "../../../components/forms/Form";
import { FormFieldProps } from "../../../components/forms/FormField";
import { createModule, updateModule } from "../../../graphql/mutations";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router";
import { getModule } from "../../../graphql/queries";
import { useHistory } from "react-router-dom";

const fields: FormFieldProps[] = [
  {
    name: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "index",
    label: "Module sort order",
    type: "number",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "textarea",
    required: true,
  },
  {
    name: "coverImage",
    label: "Cover Image URL",
    type: "file",
  },
  {
    name: "videoUrl",
    label: "Video url",
    type: "text",
    required: true,
  },
  {
    name: "delayNumber",
    label: "Wait after previous",
    type: "number",
    required: true,
  },
  {
    name: "delayUOM",
    label: "Wait unit of measure",
    type: "select",
    required: true,
    options: [
      { label: "Minutes", value: "MINUTE" },
      { label: "Hours", value: "HOUR" },
      { label: "Days", value: "DAY" },
      { label: "Weeks", value: "WEEK" },
      { label: "Months", value: "MONTH" },
    ],
  },
];

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  }),
);

export interface FormModule {
  name: string;
  index: number;
  description?: string;
  coverImage?: string | File;
  videoUrl: string;
  delayNumber: number;
  delayUOM: string;
}

const ModuleForm = (): JSX.Element => {
  const [module, setModule] = React.useState({});
  const classes = useStyle();
  const { courseId, moduleId } =
    useParams<{ courseId: string; moduleId: string }>();
  const history = useHistory();

  React.useEffect(() => {
    async function syncModule() {
      const course = await API.graphql({
        query: getModule,
        variables: { id: moduleId },
      });
      setModule((course as any).data.getModule);
    }
    if (moduleId) {
      syncModule();
    }
  }, [moduleId]);

  const onSubmit = async (value: FormModule) => {
    const file = value.coverImage;
    if (file && file instanceof File) {
      const extension = file.name.split(".").pop();
      const fileName = uuid() + "." + extension;
      const result = await Storage.put(fileName, file, {
        level: "public",
      });
      value.coverImage = (result as { key: string }).key;
    }
    if (!moduleId) {
      const result = await API.graphql({
        query: createModule,
        variables: { input: { ...value, courseId } },
      });
      history.push(`/courses/${courseId}`);
    } else {
      const input = {
        id: moduleId,
        courseId: courseId,
        name: value.name,
        index: value.index,
        description: value.description,
        coverImage: value.coverImage,
        videoUrl: value.videoUrl,
        delayNumber: value.delayNumber,
        delayUOM: value.delayUOM,
      };
      const result = await API.graphql({
        query: updateModule,
        variables: { input },
      });
      console.log(result);
      history.push(`/courses/${courseId}/modules/${moduleId}`);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">Module</Typography>
      <Form fields={fields} onSubmit={onSubmit} value={module} />
    </Paper>
  );
};

export default ModuleForm;
