import { TextField, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Storage } from "aws-amplify";
import * as React from "react";
import Image from "../Image";

export interface FileFieldProps {
  className?: string;
  label: string;
  value?: File | string;
  onChange: (value: any) => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      maxWidth: "100%",
      maxHeight: "250px",
    },
  }),
);

const FileField = (props: FileFieldProps) => {
  const classes = useStyle();
  const [src, setSrc] = React.useState("");

  const valueToSrc = async () => {
    if (props.value instanceof File) {
      setSrc(URL.createObjectURL(props.value));
    } else {
      try {
        const url = await Storage.get(props.value);
        setSrc(url);
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  React.useEffect(() => {
    valueToSrc();
  });

  return (
    <section className={props.className}>
      {props.value ? (
        <div>
          <Image src={props.value}/> 
        </div>
      ) : null}
      <TextField
        type="file"
        label={props.label}
        onChange={(e) =>
          props.onChange((e.target as HTMLInputElement).files[0])
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
    </section>
  );
};

export default FileField;
