import { Select } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import * as React from "react";
import { listCourses } from "../../graphql/queries";

interface AssignCourseDialogProps {
  open: boolean;
  onAssignCourse?: (value: string) => void;
  onReject?: () => void;
}

const AssignCourseDialog = (props: AssignCourseDialogProps): JSX.Element => {
  const { open, onAssignCourse, onReject } = props;
  const [data, setData] = React.useState<any[]>([]);
  const [value, setValue] = React.useState<any>("");

  React.useEffect(() => {
    async function syncCourses() {
      console.log("sync");
      const result = await API.graphql({
        query: listCourses,
      });
      setData((result as any).data.listCourses.items);
    }
    syncCourses();
  }, []);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onBackdropClick={onReject}
    >
      <DialogTitle>Add course to program</DialogTitle>
      <DialogContent dividers>
        <Typography>Select a course to add to this program</Typography>
        <Select onChange={(v) => setValue(v.target.value)} value={value}>
          {data.map((it) => (
            <MenuItem value={it.id} key={it.id}>
              {it.name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onReject}>
          Cancel
        </Button>
        <Button onClick={() => onAssignCourse && onAssignCourse(value)}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignCourseDialog;
