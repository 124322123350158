import { getModuleProgress } from "./queries";

export const listEnrollments = /* GraphQL */ `
  query CustomListEnrollments(
    $filter: ModelEnrollmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnrollments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        course {
          name
          id
        }
        createdAt
        id
        moduleSchedule {
          items {
            availableAt
            module {
              name
            }
          }
        }
        startedAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getUserCourse = /* GraphQL */`
  query getUserCourse (
    $id: ID!
  ) {
    getCourse(id: $id) {
      id
      name
      modules {
        items {
          courseId
          id
          index
          name
          assignments {
            items {
              id
              index
              question
            }
          }
        }
        nextToken
      }
    }
  }
`

export const getUserModule = /* GraphQL */`
  query getUserModule(
    $id: ID!
  ) {
    getModuleProgress(id: $id) {
      availableAt
      completedAt
      module {
        name
      }
      workbook {
        items {
          answer
          createdAt
          question {
            question
            introduction
            index
          }
        }
      }
    }
  }
`;

export const getSubscriptions = /* GraphQL */`
  query getSubscriptions (
    $userId: String!
  ) {
    listMemberships(filter: {cognitoId: {eq: $userId}}) {
      items {
        createdAt
        id
        program {
          name
          courses {
            items {
              course {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`