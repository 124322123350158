import API from "@aws-amplify/api";
import { Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import Single, { DataField } from "../../components/Single";
import { getCourse } from "../../graphql/queries";
import EditIcon from "@mui/icons-material/Edit";
import ModuleList from "./modules/ModuleList";
import { deleteCourse } from "../../graphql/mutations";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Header from "../../components/Header";
import Image from "../../components/Image";
import { Add, Delete } from "@mui/icons-material";

const fields: DataField[] = [
  { key: "name", label: "Name" },
  { key: "description", label: "Description" },
  { key: "coverImage", label: "Cover image", renderFunction: (value) => <Image src={value} /> },
];

interface ParamsType {
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const CourseSingle = (): JSX.Element => {
  const [course, setCourse] = React.useState({});
  const [isOpen, setOpen] = React.useState(false);
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncCourse() {
      const course = await API.graphql({
        query: getCourse,
        variables: { id: id },
      });
      setCourse((course as any).data.getCourse);
    }
    syncCourse();
  }, [id]);

  const onEditClick = () => {
    history.push(`/courses/${id}/edit`);
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  const handleDelete = async () => {
    await API.graphql({
      query: deleteCourse,
      variables: { input: { id } },
    });
    history.push("/courses");
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="Course"
          actions={[
            {
              name: "Add module",
              icon: <Add />,
              onClick: () => history.push(`/courses/${id}/modules/add`),
            },
            {
              name: "Edit course",
              icon: <EditIcon />,
              onClick: onEditClick,
            },
            {
              name: "Delete course",
              icon: <Delete />,
              onClick: onDeleteClick,
            },
          ]}
        />
        <Single data={course} fields={fields} />
      </Paper>
      <ModuleList courseId={id} />
      <ConfirmationDialog
        title="Are you sure"
        content="You're about to delete a course, this cannot be undone. Are you sure?"
        onConfirm={handleDelete}
        open={isOpen}
        onReject={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default CourseSingle;
