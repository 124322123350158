import API from "@aws-amplify/api";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import EditIcon from "@mui/icons-material/Edit";
import { Add, Delete, Refresh } from "@mui/icons-material";
import { getCourse, getEnrollment, getModuleProgress, listProfiles } from "../../../graphql/queries";
import { getUserCourse, getUserModule } from "../../../graphql/myQueries";
import Header from "../../../components/Header";
import ModuleList from "../../courses/modules/ModuleList";
import DataTable from "../../../components/lists/DataTable";
import Single from "../../../components/Single";
import client from "../../../util/apiClient";

// const fields: DataField[] = [
//   { key: "name", label: "Name" },
//   { key: "description", label: "Description" },
//   { key: "coverImage", label: "Cover image", renderFunction: (value) => <Image src={value} /> },
// ];

interface ParamsType {
  id: string;
  userId: string;
  enrollmentId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const UserCourse = (): JSX.Element => {
  const [course, setCourse] = React.useState<any>({});
  const [enrollment, setEnrollment] = React.useState<any>({});
  const [modules, setModules] = React.useState<any[]>([]);
  const [profile, setProfile] = React.useState<any>({});
  const [isOpen, setOpen] = React.useState(false);
  const { id, userId, enrollmentId } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncCourse() {

      type userResponse = { enrollment: any; moduleProgress: any[] }
      const { enrollment, moduleProgress } = await client.get<userResponse>(`admin/users/${userId}/enrollment/${enrollmentId}`);
      const course = await API.graphql({
        query: getUserCourse,
        variables: { id: enrollment.courseId },
      });

      const modules = (course as any).data.getCourse.modules.items
        .map(({ assignments={items: []}, ...rest }) => {
          console.log({rest, moduleProgress});
          const mp = moduleProgress.find(mp => rest.id === mp.progress.moduleId)
          if(!mp) {
            return {
              ...rest,
              assignments: [],
              progress: {}
            }
          }
          return {
            ...rest,
            assignments: assignments.items.map((q: any) => ({...q, answer: mp?.answers?.find(a => a.questionId === q.id)})),
            progress: mp.progress,
          }
        });
      console.log(modules);

      setCourse((course as any).data.getCourse);
      setEnrollment(enrollment);
      setModules(modules);

    }
    syncCourse();
  }, [enrollmentId]);


  const onEditClick = () => {
    history.push(`/courses/${id}/edit`);
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  const onRegenerate = async () =>{
    const {result} = await client.post<{result: boolean}>(`admin/users/${userId}/enrollments/${enrollmentId}/regenerate`, {});
  }

  //   const handleDelete = async () => {
  //     await API.graphql({
  //       query: deleteCourse,
  //       variables: { input: { id } },
  //     });
  //     history.push("/courses");
  //   };

  // const answerSheet = ({data} : {data: any}) => <p>{data}</p>

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title={course?.name}
          actions={[
            {
              name: 'Regenerate modules',
              icon: <Refresh />,
              onClick: onRegenerate,
            }
          ]}
        />
        <Single
          data={enrollment}
          fields={[{ key: 'startedAt', label: 'Started at' }, { key: 'completedAt', label: 'Completed at' },]}
        />
      </Paper>
      <Paper>
        <DataTable
          columns={[{ field: "name", headerName: "Module name" }, { field: "progress.availableAt", headerName: "Available at" }, { field: "progress.completedAt", headerName: "Completed at" }]}
          data={[...(modules ?? [])].sort((a, b) => a.index - b.index)}
          ExpandComponent={AnswerSheet}
        />
      </Paper>
    </React.Fragment>
  );
};

const AnswerSheet = (props: { data: any }): JSX.Element => {
  const workbook = props.data.assignments.map(item => (item ? {
    index: item.index,
    question: item.question,
    answer: item.answer?.answer
  } : null));
  workbook.sort((a, b) => a.index - b.index);
  return <div>
    <Typography variant="h6">Workbook</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row">Index</TableCell>
          <TableCell>Question</TableCell>
          <TableCell>Answer</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {workbook.map((item) => <TableRow key={item.index}>
          <TableCell>{item.index}</TableCell>
          <TableCell>{item.question}</TableCell>
          <TableCell style={{ whiteSpace: "pre-wrap" }}>{item.answer}</TableCell>
        </TableRow>)
        }
      </TableBody>
    </Table>
  </div>

}

export default UserCourse;
