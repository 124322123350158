import {
  Divider,
  Typography,
  Drawer,
  List,
  ListItem,
  AppBar,
  Button,
  Theme,
  Toolbar,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";
import * as React from "react";

import { Switch, Route, Link } from "react-router-dom";
import CourseForm from "../screens/courses/CourseForm";
import HomeScreen from "../screens/HomeScreen";
import UserList from "../screens/users/UserList";
import UserSingle from "../screens/users/UserSingle";
import UserForm from "../screens/users/UserForm";
import { Route as IRoute } from "./types";
import CourseList from "../screens/courses/CourseList";
import CourseSingle from "../screens/courses/CourseSingle";
import ModuleList from "../screens/courses/modules/ModuleList";
import ModuleForm from "../screens/courses/modules/ModuleForm";
import ModuleSingle from "../screens/courses/modules/ModuleSingle";
import QuestionForm from "../screens/courses/question/QuestionForm";
import QuestionSingle from "../screens/courses/question/QuestionSingle";
import DownloadableForm from "../screens/courses/downloadable/DownloadableForm";
import DownloadableSingle from "../screens/courses/downloadable/DownloadableSingle";
import ProgramList from "../screens/programs/ProgramList";
import ProgramForm from "../screens/programs/ProgramForm";
import ProgramSingle from "../screens/programs/ProgramSingle";
import { useDispatch } from "react-redux";
import { requestSignOut } from "../store/auth/actions";
import UserCourse from "../screens/users/course/UserCourse";

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
    },
    appBar: {
      zIndex: theme.zIndex?.drawer + 1,
    },
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: "auto",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
  }),
);

const routes: IRoute[] = [
  {
    path: "/programs",
    component: ProgramList,
    showInMenu: true,
    menuName: "Programs",
  },
  {
    path: "/programs/add",
    component: ProgramForm,
    showInMenu: false,
    menuName: "Programs",
  },
  {
    path: "/programs/:id",
    component: ProgramSingle,
    showInMenu: false,
    menuName: "Programs",
  },
  {
    path: "/programs/:id/edit",
    component: ProgramForm,
    showInMenu: false,
    menuName: "Programs",
  },
  {
    path: "/courses",
    component: CourseList,
    showInMenu: true,
    menuName: "Courses",
  },
  {
    path: "/courses/add",
    component: CourseForm,
    showInMenu: false,
    menuName: "Courses",
  },
  {
    path: "/courses/:id",
    component: CourseSingle,
    showInMenu: false,
    menuName: "Courses",
  },
  {
    path: "/courses/:id/edit",
    component: CourseForm,
    showInMenu: false,
    menuName: "Courses",
  },
  {
    path: "/courses/:courseId/modules",
    component: ModuleList,
    showInMenu: false,
    menuName: "Modules",
  },
  {
    path: "/courses/:courseId/modules/add",
    component: ModuleForm,
    showInMenu: false,
    menuName: "Modules",
  },
  {
    path: "/courses/:courseId/modules/:moduleId",
    component: ModuleSingle,
    showInMenu: false,
    menuName: "Modules",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/edit",
    component: ModuleForm,
    showInMenu: false,
    menuName: "Modules",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/questions/add",
    component: QuestionForm,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/questions/:questionId",
    component: QuestionSingle,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/questions/:questionId/edit",
    component: QuestionForm,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/tools/add",
    component: DownloadableForm,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/tools/:downloadableId",
    component: DownloadableSingle,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/courses/:courseId/modules/:moduleId/tools/:downloadableId/edit",
    component: DownloadableForm,
    showInMenu: false,
    menuName: "Questions",
  },
  {
    path: "/users",
    component: UserList,
    showInMenu: true,
    menuName: "Users",
  },
  {
    path: "/users/add",
    component: UserForm,
    showInMenu: false,
    menuName: "Users",
  },
  {
    path: "/users/:id",
    component: UserSingle,
    showInMenu: false,
    menuName: "Users",
  },
  {
    path: "/users/:userId/course/:enrollmentId",
    component: UserCourse,
    showInMenu: false,
    menuName: "Users"
  },
  { path: "/", component: HomeScreen, showInMenu: false },
];

const AppStack = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(requestSignOut());
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            MFC Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {routes
              .filter((route) => route.showInMenu)
              .map((route) => (
                <Link key={route.path} to={route.path} className={classes.link}>
                  <ListItem button>{route.menuName ?? ""}</ListItem>
                </Link>
              ))}
          </List>
        </div>
        <Divider />
        <Button onClick={handleLogout}>Sign out</Button>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              exact={true}
            />
          ))}
        </Switch>
      </main>
    </div>
  );
};

export default AppStack;
