import { Auth } from "aws-amplify";
import { SignInRequest, SignOutRequest } from "./types";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
} from "./actions";
import { CognitoUser } from "amazon-cognito-identity-js";
import { SIGN_IN_REQUEST, SIGN_OUT_REQUEST } from "./actionTypes";

function* initialSaga() {
  try {
    const user = yield call([Auth, "currentAuthenticatedUser"]);
    if (user) {
      yield put(signInSuccess());
    }
  } catch (ex) {}
}

function* signIn(action: SignInRequest) {
  const { username, password } = action.payload;
  try {
    const user: CognitoUser = yield call([Auth, "signIn"], username, password);
    if (user !== null) {
      const groups: string[] = user.getSignInUserSession().getAccessToken()
        .payload["cognito:groups"];
      console.log(groups);
      if (groups.includes("admin")) {
        yield put(signInSuccess());
      } else {
        yield put(signInFailure("Not allowed"));
        yield call([Auth, "signOut"]);
      }
    }
  } catch (ex) {
    yield put(signInFailure(ex.toString()));
  }
}

function* signOut(action: SignOutRequest) {
  try {
    yield call([Auth, "signOut"]);
    yield put(signOutSuccess());
  } catch (ex) {
    yield put(signOutFailure(ex));
  }
}

function* authSaga() {
  yield fork(initialSaga);
  yield all([
    takeLatest(SIGN_IN_REQUEST, signIn),
    takeLatest(SIGN_OUT_REQUEST, signOut),
  ]);
}

export default authSaga;
