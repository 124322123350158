import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import FileField from "./FileField";

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface FormFieldProps {
  label: string;
  name: string;
  placeholder?: string;
  id?: string;
  type:
    | "text"
    | "textarea"
    | "date"
    | "number"
    | "boolean"
    | "select"
    | "file"
    | "array";
  required?: boolean;
  value?: any;
  onChange?: (value: any) => void;
  options?: SelectOption[];
  fields?: FormFieldProps[];
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    inputField: {
      margin: theme.spacing(1),
    },
  }),
);

const FormField = (props: FormFieldProps) => {
  const classes = useStyle();

  switch (props.type) {
    case "text":
      return (
        <TextField
          label={props.label}
          className={classes.inputField}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          required={props.required}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      );
    case "textarea":
      return (
        <TextField
          label={props.label}
          className={classes.inputField}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          required={props.required}
          maxRows={10}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          multiline
        />
      );
    case "number":
      return (
        <TextField
          type="number"
          className={classes.inputField}
          label={props.label}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          required={props.required}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      );
    case "boolean":
      return (
        <FormControlLabel
          control={
            <Switch
              checked={!!props.value}
              onChange={(e) => props.onChange(e.target.checked)}
              id={props.id}
              name={props.name}
              color="primary"
            />
          }
          label={props.label}
        />
      );
    case "date":
      return (
        <TextField
          type="date"
          label={props.label}
          className={classes.inputField}
          placeholder={props.placeholder}
          id={props.id}
          name={props.name}
          required={props.required}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    case "file":
      return (
        <FileField
          className={classes.inputField}
          label={props.label}
          value={props.value}
          onChange={(e) => props.onChange(e)}
        />
      );
    case "select":
      return (
        <FormControl className={classes.inputField}>
          <InputLabel id={props.name}>{props.label}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.value}
            label={props.label}
            onChange={(e) => props.onChange(e.target.value)}
          >
            {props.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "array":
      const onAdd = () => {
        if (!props.value || props.value === "") {
          props.onChange([{}]);
        } else {
          props.onChange([...props.value, {}]);
        }
      };
      const onDelete = (idx) => {
        props.onChange([
          ...props.value.slice(0, idx),
          ...props.value.slice(idx + 1),
        ]);
      };
      const onChange = (idx, fieldName, value) => {
        props.onChange([
          ...props.value.slice(0, idx),
          {
            ...props.value[idx],
            [fieldName]: value,
          },
          ...props.value.slice(idx + 1),
        ]);
      };
      return (
        <section className={classes.inputField}>
          <Typography variant="h6">{props.label}</Typography>
          {props.value && props.value != ""
            ? props.value.map((value, idx) => (
                <section key={idx}>
                  {props.fields?.map((field) => (
                    <FormField
                      key={field.name}
                      {...field}
                      onChange={(value) => onChange(idx, field.name, value)}
                      value={value[field.name] || ""}
                    />
                  ))}
                  <Button onClick={() => onDelete(idx)}>Delete</Button>
                </section>
              ))
            : null}
          <Button onClick={onAdd}>Add</Button>
        </section>
      );
  }
};

export default FormField;
