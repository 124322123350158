import { MoreVert } from "@mui/icons-material";
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Theme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";

interface HeaderAction {
  name: string;
  icon?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
}

interface HeaderProps {
  title: string;
  actions: HeaderAction[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const Header = (props: HeaderProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <section className={classes.header}>
        <Typography variant="h4">{props.title}</Typography>
        <IconButton onClick={onMenuClick}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onMenuClose}
        >
          {props.actions.map((action) => (
            <MenuItem
              key={action.name}
              disabled={action.disabled}
              onClick={() => {
                onMenuClose();
                if(action.onClick) {
                  action.onClick();
                }
              }}
            >
              {action.icon ? <ListItemIcon>{action.icon}</ListItemIcon> : null}
              {action.name}
            </MenuItem>
          ))}
        </Menu>
      </section>
      <Divider className={classes.divider} />
    </React.Fragment>
  );
};

export default Header;
