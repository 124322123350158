import {Storage} from "@aws-amplify/storage";
import API from "@aws-amplify/api";
import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import Form from "../../components/forms/Form";
import { FormFieldProps } from "../../components/forms/FormField";
import { createProgram, updateProgram } from "../../graphql/mutations";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router";
import { getProgram } from "../../graphql/queries";
import { useHistory } from "react-router-dom";
import { CreateProgramInput } from "../../API";

const fields: FormFieldProps[] = [
  {
    name: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "isForSale",
    label: "Is for sale",
    type: 'boolean',
    required: true,
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    required: false,
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
    required: true,
  },
  {
    name: 'imageUrl',
    label: 'Image',
    type: 'file',
    required: true,
  },
  {
    name: 'productUrl',
    label: 'URL to product page',
    type: 'text',
    required: true,
  },
  {
    name: 'productStoreId',
    label: 'Product ID in app stores',
    type: 'text',
    required: true
  }
];

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  }),
);

export interface FormProgram {
  name: string;
  description: string;
  imageUrl?: string | File;
  isForSale: boolean;
  price?: number;
  productUrl: string;
  productStoreId: string;
}

const ProgramForm = (): JSX.Element => {
  const [program, setProgram] = React.useState({});
  const classes = useStyle();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    async function syncProgram() {
      const program = await API.graphql({
        query: getProgram,
        variables: { id: id },
      });
      setProgram((program as any).data.getProgram);
    }
    if (id) {
      syncProgram();
    }
  }, [id]);

  const onSubmit = async (value: FormProgram) => {
    const file = value.imageUrl;
    let imageUrl;
    if (file && file instanceof File) {
      const extension = file.name.split(".").pop();
      const fileName = uuid() + "." + extension;
      const result = await Storage.put(fileName, file, {
        level: "public",
      });
      imageUrl = (result as { key: string }).key;
    } else {
      imageUrl = file;
    }

    const input: CreateProgramInput = {
      name: value.name,
      description: value.description,
      imageUrl: imageUrl,
      isForSale: value.isForSale,
      price: value.price,
      productStoreId: value.productStoreId,
      productUrl: value.productUrl
    };
    console.log(input);
    if (!id) {
      const result = await API.graphql({
        query: createProgram,
        variables: { input },
      });
      history.push("/programs");
    } else {
      const result = await API.graphql({
        query: updateProgram,
        variables: { input: { id, ...input } },
      });
      history.push(`/programs/${id}`);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">Program</Typography>
      <Form fields={fields} onSubmit={onSubmit} value={program} />
    </Paper>
  );
};

export default ProgramForm;
