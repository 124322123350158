import Storage from "@aws-amplify/storage";
import API from "@aws-amplify/api";
import { Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import Form from "../../components/forms/Form";
import { FormFieldProps } from "../../components/forms/FormField";
import { createCourse, updateCourse } from "../../graphql/mutations";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router";
import { getCourse } from "../../graphql/queries";
import { useHistory } from "react-router-dom";

const fields: FormFieldProps[] = [
  {
    name: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    name: "description",
    label: "Description",
    type: "textarea",
  },
  {
    name: "coverImage",
    label: "Cover Image URL",
    type: "file",
  },
];

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
    },
  }),
);

export interface FormCourse {
  name: string;
  description?: string;
  coverImage?: string | File;
}

const CourseForm = (): JSX.Element => {
  const [course, setCourse] = React.useState({});
  const classes = useStyle();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    async function syncCourse() {
      const course = await API.graphql({
        query: getCourse,
        variables: { id: id },
      });
      setCourse((course as any).data.getCourse);
    }
    if (id) {
      syncCourse();
    }
  }, [id]);

  const onSubmit = async (value: FormCourse) => {
    const file = value.coverImage;
    if (file && file instanceof File) {
      const extension = file.name.split(".").pop();
      const fileName = uuid() + "." + extension;
      const result = await Storage.put(fileName, file, {
        level: "public",
      });
      value.coverImage = (result as { key: string }).key;
    }
    const input = {
      name: value.name,
      accessGroup: value.name.replace(/ /g, "_").toLowerCase(),
      description: value.description,
      coverImage: value.coverImage,
    };
    if (!id) {
      const result = await API.graphql({
        query: createCourse,
        variables: { input },
      });
      history.push("/courses");
    } else {
      const result = await API.graphql({
        query: updateCourse,
        variables: { input: { id, ...input } },
      });
      history.push(`/courses/${id}`);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">Course</Typography>
      <Form fields={fields} onSubmit={onSubmit} value={course} />
    </Paper>
  );
};

export default CourseForm;
