import API, { GraphQLResult } from '@aws-amplify/api';
import { GraphQLOptions } from '@aws-amplify/api-graphql';

export async function fetchAll<T> (queryName: string, options: GraphQLOptions): Promise<T[]> {
    let nextToken;
    let collect: any[] = [];
    do {

        const result = await API.graphql({
            ...options,
            variables: {
                ...options.variables,
                nextToken,
            } 
        });
        nextToken = (result as any).data[queryName].nextToken;
        collect = [...collect, ...(result as any).data[queryName].items]
    } while (nextToken)
    return collect;
}