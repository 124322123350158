import * as React from 'react';
import { Storage } from "aws-amplify";
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export interface ImageProps {
    src: string | File;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      maxWidth: "100%",
      maxHeight: "250px",
    },
  }),
);

const Image = (props: ImageProps) => {
  const classes = useStyle();
    const [src, setSrc] = React.useState<string>('');

    const valueToSrc = async () => {
        if (props.src instanceof File) {
          setSrc(URL.createObjectURL(props.src));
        } else {
          try {
            const url = await Storage.get(props.src);
            setSrc(url);
          } catch (ex) {
            console.log(ex);
          }
        }
      };
    
      React.useEffect(() => {
        valueToSrc();
      });
      
      return <img className={classes.image} src={src} />
}

export default Image;