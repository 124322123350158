import {
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
} from "./actionTypes";
import { AuthState, AuthActions } from "./types";

const initialState: AuthState = {
  pending: false,
  error: null,
  authenticated: false,
};

export default (state = initialState, action: AuthActions): AuthState => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        authenticated: true,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
