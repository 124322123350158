import * as React from 'react';
import Single, { DataField } from '../../../components/Single';
import { Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory, useParams } from "react-router";
import { API, Storage } from 'aws-amplify';
import { deleteDownloadable } from '../../../graphql/mutations';
import { getDownloadable } from '../../../graphql/queries';
import Header from '../../../components/Header';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const fields: DataField[] = [
  { key: 'index', label: "Index" },
    { key: 'name', label: 'Name'},
    { key: 'url', label: 'Url' }
]

interface ParamsType {
  courseId: string;
  moduleId: string;
  downloadableId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const DownloadableSingle = () : JSX.Element => {
    const [downloadable, setDownloadable] = React.useState<any>({});
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const { courseId, moduleId, downloadableId } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncDownloadable() {
      const downloadable = await API.graphql({
        query: getDownloadable,
        variables: { id: downloadableId },
      });
      const theDownloadable = (downloadable as any).data.getDownloadable;
      setDownloadable(
        theDownloadable
      );
    }
    syncDownloadable();
  }, [downloadableId]);

  const onEdit = () => {
    history.push(
      `/courses/${courseId}/modules/${moduleId}/tools/${downloadableId}/edit`,
    );
  };

  const handleDelete = async () => {
    await Storage.remove(downloadable.url);
    await API.graphql({
      query: deleteDownloadable,
      variables: { input: { id: downloadableId } },
    });
    history.push(`/courses/${courseId}/modules/${moduleId}`);
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="Downloadable"
          actions={[
            {
              name: "Edit tool",
              icon: <EditIcon />,
              onClick: onEdit,
            },
            {
              name: "Delete tool",
              icon: <DeleteIcon />,
              onClick: onDeleteClick,
            },
          ]}
        />
        <Single data={downloadable} fields={fields} />
      </Paper>
      <ConfirmationDialog
        title="Are you sure"
        content="You're about to delete this tool, this cannot be undone. Are you sure?"
        onConfirm={handleDelete}
        open={isOpen}
        onReject={() => setOpen(false)}
      />
    </React.Fragment>
  );
}

export default DownloadableSingle;