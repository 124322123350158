import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import API, { GraphQLResult } from "@aws-amplify/api";
import * as React from "react";
import Form from "../../../components/forms/Form";
import * as mutations from "../../../graphql/mutations";
import { ListProgramsQuery } from "../../../API";
import { listCourses, listEnrollments } from "../../../graphql/queries";
import { FormFieldProps } from "../../../components/forms/FormField";
import client from "../../../util/apiClient";


interface EnrollmentFormProps {
  userId: string;
  profileId: string;
  enrollments: any[];
  open: boolean;
  onClose: () => void;
}

const ChangeCourseFormDialog = (props: EnrollmentFormProps): JSX.Element => {
  const { open, onClose, userId } = props;
  const [courses, setCourses] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    async function fetchPrograms() {
      const result = await API.graphql({
        query: listCourses,
      });
      setCourses(
        (result as any).data.listCourses.items,
      );
    }
    fetchPrograms();
  }, [userId]);

  const fields: FormFieldProps[] = [
    {
      name: "courseId",
      label: "Course",
      type: "select",
      required: true,
      options: courses.map((c) => ({ label: c.name, value: c.id })),
    },
  ];

  const onSubmit = async (value) => {
    setLoading(true);
    try {
      const result = await client.put(`admin/users/${props.userId}`, {
        id: props.profileId,
        currentCourseId: value.courseId,
      });

      const enrollment = props.enrollments.find(e => e.courseId === value.courseId);
      if (!enrollment) {
        const result = await client.post(`admin/users/${props.userId}/enroll`, {
          courseId: value.courseId,
          startedAt: new Date().toISOString(),
        });
      }
    } catch (ex) {
      alert(ex);
    }

    setLoading(false);


    onClose();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      onBackdropClick={onClose}
    >
      <DialogTitle>Change current course</DialogTitle>
      <DialogContent>
        <Typography>Please note, only choose a course from a program the user subscribed to!</Typography>
        <Form fields={fields} onSubmit={onSubmit} value={{}} loading={loading}/>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeCourseFormDialog;
