import { Button, CircularProgress, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import FormField, { FormFieldProps } from "./FormField";

export interface FormProps {
  value: { [key: string]: any };
  onSubmit: (newValue: any, change: any) => void;
  fields: FormFieldProps[];
  loading?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flex: 1,
      flexDirection: "column",
      display: "flex",
    },
    buttonRow: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

const Form = (props: FormProps) => {
  const [delta, setDelta] = React.useState({});
  const classes = useStyle();

  const onChange = (field: string) => {
    return (value) => {
      setDelta({ ...delta, [field]: value });
    };
  };

  const onSubmit: React.FormEventHandler = (e: React.FormEvent) => {
    e.preventDefault();
    props.onSubmit({ ...props.value, ...delta }, delta);
  };

  const getValue = (fieldName: string) => {
    if (Object.keys(delta).includes(fieldName)) {
      return delta[fieldName];
    }
    if (Object.keys(props.value).includes(fieldName)) {
      return props.value[fieldName];
    }
    return '';
  }

  return (
    <form noValidate className={classes.form} onSubmit={onSubmit}>
      {props.fields.map((field) => (
        <FormField
          key={field.id || field.name}
          name={field.name}
          id={field.id || field.name}
          required={field.required}
          label={field.label}
          placeholder={field.placeholder || field.label}
          type={field.type}
          onChange={onChange(field.name)}
          value={getValue(field.name)}
          options={field.options}
          fields={field.fields}
        />
      ))}
      <div className={classes.buttonRow}>
        {props.loading ?
          <CircularProgress /> :
          <Button
            type="submit"
            className={classes.button}
            color="primary"
            variant="contained"
          >
            Opslaan
          </Button>}
      </div>
    </form>
  );
};

export default Form;
