import * as React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { Theme } from "@mui/material";
import { fetchAll } from '../../../util/apiHelpers';
import { listDownloadables } from '../../../graphql/queries';
import DataTable from '../../../components/lists/DataTable';

const fields = [
  {field: "index", headerName: "Index"},
    {field: "name", headerName: "Name" },
    {field: "url", headerName: "URL" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "500px",
      position: "relative",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

export interface DownloadableListProps {
    courseId?: string;
    moduleId?: string;
}

const DownloadableList = (props: DownloadableListProps): JSX.Element => {
    const classes = useStyles();
    const [data, setData] = React.useState<any[]>([]);
    const history = useHistory();
    const { courseId, moduleId } = props;

    React.useEffect(() => {
        async function syncDownloadables() {
    
          setData(await fetchAll('listDownloadables', {
            query: listDownloadables,
            variables: { filter: { moduleId: { eq: moduleId } } },
          }));
        }
        syncDownloadables();
      }, [moduleId]);

      const onRowClick = (row) => {
        return history.push(`/courses/${courseId}/modules/${moduleId}/tools/${row.id}`)
      }

      return <DataTable 
        columns={fields}
        data={data}
        onRowClick={onRowClick}
      />
}

export default DownloadableList;