import API, { GraphQLResult } from "@aws-amplify/api";
import { Alert, CircularProgress, Icon, Paper, Theme, Typography } from "@mui/material";
import * as React from "react";
import Header from "../../components/Header";
import { getSubscriptions, listEnrollments } from "../../graphql/myQueries";
import * as mutations from "../../graphql/mutations";
import {
  listProgramCourses,
  listPrograms,
} from "../../graphql/queries";
import { Add, Check, Delete, Edit, ImportExport } from "@mui/icons-material";
import Single, { DataField } from "../../components/Single";
import { useHistory, useParams } from "react-router-dom";
import DataTable, { Column } from "../../components/lists/DataTable";
import EnrollmentFormDialog from "./enrollment/EnrollmentForm";
import { CognitoUser } from "../../API";
import { createStyles, makeStyles } from "@mui/styles";
import { Observable } from "redux";
import ChangeCourseFormDialog from "./enrollment/ChangeCourseForm";
import client from "../../util/apiClient";
import { fetchAll } from "../../util/apiHelpers";

interface ParamsType {
  id: string;
}

const courseColumns: Column[] = [
  {
    field: "isActive",
    headerName: "Is current",
    renderFn: (val) => val ? <Icon><Check /></Icon> : undefined
  },
  {
    field: "course.name",
    headerName: "Course",
  },
  {
    field: "enrollment.startedAt",
    headerName: "Start date",
    renderFn: (date) => date && new Date(date).toDateString(),
  },
  {
    field: "enrollment.completedAt",
    headerName: "Completed date",
    renderFn: (date) => date && new Date(date).toDateString(),
  },
  {
    field: "progress",
    headerName: "Modules available",
    renderFn: (progress) => {
      if(!progress) return undefined;
      const available = progress.items.filter(
        (x: { availableAt: string }) =>
          new Date(x.availableAt).valueOf() < Date.now(),
      ).length;
      const total = progress.items.length;
      return `${available} of ${total}`;
    },
  },
];

const profileFields: DataField[] = [
  { key: "firstName", label: "First name" },
  { key: "lastName", label: "Last name" },
  { key: "birthDate", label: "Birthdate" },
  { key: "length", label: "Height" },
  { key: "targetWeight", label: "Target weight" },
];

const membershipColumns: Column[] = [
  { field: "program.name", headerName: "Program" },
  {
    field: "createdAt",
    headerName: "Subscribed at",
    renderFn: (value) => value.split("T")[0],
  },
  {
    field: "endDate",
    headerName: "Ends at",
    renderFn: (value) => value ? value.split("T")[0] : '-',
  },
];

const measurementColumns: Column[] = [
  { field: "date", headerName: "Measurement date" },
  { field: "weight", headerName: "Weight" },
  { field: "note", headerName: "Note" },
  { field: "createdAt", headerName: "Date entered" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    spacer: {
      height: "20px",
    },
  }),
);

const UserSingle = (): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [changeCourseOpen, setChangeCourseOpen] = React.useState(false);
  const [profile, setProfile] = React.useState<any>({});
  const [memberships, setMemberships] = React.useState<any>([]);
  const [measurements, setMeasurements] = React.useState<any[]>([]);
  const [enrollments, setEnrollments] = React.useState<any[]>([]);
  const [progress, setProgress] = React.useState<any[]>([]);
  const [programs, setPrograms] = React.useState<any[]>([]);
  const [programCourses, setProgramCourses] = React.useState<any[]>([]);
  const [courses, setCourses] = React.useState<any[]>([]);
  const [contentDone, setContentDone] = React.useState<boolean>(false);
  const [loadingImport, setLoadingImport] = React.useState<boolean>(false);
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  const userId = decodeURIComponent(id);

  const syncUser = async () => {
    type userResponse = { profile: any; measurements: any[], enrollments: any[], memberships: any[], progress: any[] }
    const user = await client.get<userResponse>(`admin/users/${userId}`);
    if(!user) {
      return;
    }
    const { profile, measurements, enrollments, memberships, progress } = user;

    const membership = memberships.map(m => ({ ...m, program: programs.find(p => p.id === m.programId) }));

    const courses = programCourses
      .filter(cp => memberships.find(m => m.programId === cp.programId))
      .map(cp => cp.course)
      .map(c => ({
        isActive: profile && profile.currentCourseId === c.id,
        course: c,
        enrollment: enrollments.find(e => e.courseId === c.id),
        progrss: progress.filter(p => p.courseId === c.id),
      }));
    setCourses(courses);

    setProfile(profile);
    setMeasurements(measurements);
    setEnrollments(user.enrollments);
    setMemberships(membership);
    setProgress(user.progress)
  };

  React.useEffect(() => {
    syncUser();
  }, [contentDone]);

  const syncContent = async () => {
    const programs = await fetchAll('listPrograms', { query: listPrograms });
    setPrograms(programs);
    const programCourses: any[] = await fetchAll('listProgramCourses', { query: listProgramCourses });
    setProgramCourses(programCourses);
    setContentDone(true);
  };

  React.useEffect(() => {
    syncContent();
  }, [])

  const onEnrollmentClick = (course) => {
    if (!course.enrollment) return;
    history.push(`/users/${id}/course/${course.enrollment.id}`)
  }

  const onAddCourseClick = () => {
    setOpen(true);
  };

  const onChangeCourseClick = () => {
    setChangeCourseOpen(true);
  }

  const onCloseDialog = () => {
    setOpen(false);
    syncUser();
  };

  const onCloseChangeCourseDialog = () => {
    setChangeCourseOpen(false);
    syncUser();
    syncContent();
  }

  const onImportClick = async () => {
    setLoadingImport(true);
    try {
      const result = await client.post(`admin/users/${userId}/import`, {});
    } catch(ex) {
      alert(ex);
    }
    setLoadingImport(false);
  }

  const onEndSubscription = async (membership) => {
    const result = await API.graphql({
      query: mutations.updateMembership,
      variables: {
        input: {
          id: membership.id,
          endDate: new Date().toISOString()
        }
      }
    })
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="User"
          actions={[
            {
              name: "Subscribe",
              icon: <Add />,
              onClick: onAddCourseClick,
            },
            {
              name: "Change active course",
              icon: <Edit />,
              onClick: onChangeCourseClick,
              disabled: !profile,
            },
            {
              name: "Import data",
              icon: loadingImport ? <CircularProgress /> : <ImportExport />,
              onClick: loadingImport ? undefined : onImportClick,
            }
          ]}
        />
        <Single data={profile} fields={profileFields} />
      </Paper>
      <Typography component='h2' >Subscriptions</Typography>
      <DataTable
        columns={membershipColumns}
        data={memberships}
        onRowClick={console.log}
        rowActions={[
          { label: "End subscription", onClick: console.log }
        ]}
      />
      <div className={classes.spacer} />
      <Typography component='h2'>Courses</Typography>
      <DataTable
        columns={courseColumns}
        data={courses}
        onRowClick={onEnrollmentClick} />
      <div className={classes.spacer} />
      <EnrollmentFormDialog
        open={open}
        onClose={onCloseDialog}
        userId={userId ?? ""}
      />
      <ChangeCourseFormDialog
        open={changeCourseOpen}
        onClose={onCloseChangeCourseDialog}
        userId={profile?.userId ?? ""}
        enrollments={enrollments}
        profileId={profile?.id}
      />

      <Typography component='h2'>Measurements</Typography>
      <DataTable
        columns={measurementColumns}
        data={measurements}
        onRowClick={console.log}
      />
    </React.Fragment>
  );
};

export default UserSingle;
