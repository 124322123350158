/* eslint-disable react/display-name */
import API from "@aws-amplify/api";
import { IconButton, Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import Single, { DataField } from "../../components/Single";
import { getProgram, listProgramCourses } from "../../graphql/queries";
import EditIcon from "@mui/icons-material/Edit";
import {
  createProgramCourse,
  deleteProgram,
  deleteProgramCourse,
} from "../../graphql/mutations";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Header from "../../components/Header";
import { Add, Delete } from "@mui/icons-material";
import DataTable from "../../components/lists/DataTable";
import AssignCourseDialog from "./AssignCourseDialog";
import CourseList from "../courses/CourseList";

const fields: DataField[] = [
  { key: "name", label: "Name" },
  { key: 'isForSale', label: 'For sale', renderFunction: val => val ? "yes" : "no" }, 
  { key: 'price', label: 'Price' }, 
  { key: 'productStoreId', label: 'App store id' }
];

interface ParamsType {
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const ProgramSingle = (): JSX.Element => {
  const [program, setProgram] = React.useState({});
  const [courses, setCourses] = React.useState([]);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [isCourseOpen, setCourseOpen] = React.useState(false);
  const { id } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncProgram() {
      const program = await API.graphql({
        query: getProgram,
        variables: { id: id },
      });
      setProgram((program as any).data.getProgram);
    }
    syncProgram();
  }, [id]);

  async function syncProgramCourses() {
    const result = await API.graphql({
      query: listProgramCourses,
      variables: { filter: { programId: { eq: id } } },
    });
    setCourses((result as any).data.listProgramCourses.items);
  }

  React.useEffect(() => {
    syncProgramCourses();
  }, [id]);

  const onEditClick = () => {
    history.push(`/programs/${id}/edit`);
  };

  const onDeleteClick = () => {
    setDeleteOpen(true);
  };

  const onAddCourseClick = () => {
    setCourseOpen(true);
  };

  const onAddCouse = async (value: string) => {
    const input = {
      programId: id,
      courseId: value,
    };
    await API.graphql({
      query: createProgramCourse,
      variables: { input },
    });
    syncProgramCourses();
    setCourseOpen(false);
  };

  const onDeleteCourse = async (value: string) => {
    await API.graphql({
      query: deleteProgramCourse,
      variables: { input: { id: value } },
    });
    syncProgramCourses();
  };

  const handleDelete = async () => {
    await API.graphql({
      query: deleteProgram,
      variables: { input: { id } },
    });
    history.push("/programs");
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="Program"
          actions={[
            {
              name: "Add course",
              icon: <Add />,
              onClick: onAddCourseClick,
            },
            {
              name: "Edit program",
              icon: <EditIcon />,
              onClick: onEditClick,
            },
            {
              name: "Delete program",
              icon: <Delete />,
              onClick: onDeleteClick,
            },
          ]}
        />
        <Single data={program} fields={fields} />
      </Paper>
      <DataTable
        columns={[
          { field: "course.name", headerName: "Name" },
          {
            field: "id",
            headerName: "actions",
            renderFn: (id: string) => (
              <IconButton onClick={() => onDeleteCourse(id)}>
                <Delete />
              </IconButton>
            ),
          },
        ]}
        data={courses}
      />
      <AssignCourseDialog
        onAssignCourse={onAddCouse}
        open={isCourseOpen}
        onReject={() => setCourseOpen(false)}
      />
      <ConfirmationDialog
        title="Are you sure"
        content="You're about to delete a program, this cannot be undone. Are you sure?"
        onConfirm={handleDelete}
        open={isDeleteOpen}
        onReject={() => setDeleteOpen(false)}
      />
    </React.Fragment>
  );
};

export default ProgramSingle;
