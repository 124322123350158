import API from "@aws-amplify/api";
import { Fab, Paper, Theme } from "@mui/material";
import * as React from "react";
import DataTable from "../../components/lists/DataTable";
import { listPrograms } from "../../graphql/queries";
import AddIcon from "@mui/icons-material/Add";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router";

const fields = [
  { field: "name", headerName: "Name" }, 
  { field: 'isForSale', headerName: 'For sale', renderFn: val => val ? "yes" : "no" }, 
  { field: 'price', headerName: 'Price' }, 
  { field: 'productStoreId', headerName: 'App store id' }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "500px",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const ProgramList = (): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    async function syncPrograms() {
      const result = await API.graphql({ query: listPrograms });
      setData((result as any).data.listPrograms.items);
    }
    syncPrograms();
  }, []);

  const onRowClick = (row) => {
    history.push(`/programs/${row.id}`);
  };

  const onFabClick = () => {
    history.push(`/programs/add`);
  };

  return (
    <div>
      <DataTable columns={fields} data={data} onRowClick={onRowClick} />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={onFabClick}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default ProgramList;
