import * as React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import LoginScreen from "../screens/LoginScreen";
import AppStack from "./DrawerStack";
import PrivateRoute from "./PrivateRoute";

const CoreStack = () => {
  return (
    <Switch>
      <Route path="/sign-in" component={LoginScreen} />
      <PrivateRoute component={AppStack} />
    </Switch>
  );
};

export default CoreStack;
