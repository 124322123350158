import { Theme } from "@mui/material";
import API from "@aws-amplify/api";
import * as React from "react";
import DataTable from "../../../components/lists/DataTable";
import { listModules } from "../../../graphql/queries";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { fetchAll } from "../../../util/apiHelpers";

const fields = [
  { field: "index", headerName: "Index" },
  { field: "name", headerName: "Name" },
  {
    field: "createdAt",
    headerName: "Created at",
  },
  { field: "delayNumber", headerName: "Delay duration" },
  { field: "delayUOM", headerName: "Delay units" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "500px",
      position: "relative",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

export interface ModuleListProps {
  courseId?: string;
}

const ModuleList = (props: ModuleListProps): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = React.useState<any[]>([]);
  const history = useHistory();
  const { courseId } = props;

  React.useEffect(() => {
    async function syncCourses() {

      setData(await fetchAll('listModules', {
        query: listModules,
        variables: { filter: { courseId: { eq: courseId } } },
      }));
    }
    syncCourses();
  }, [courseId]);

  const onRowClick = (row) => {
    console.log(row);
    return history.push(`/courses/${courseId}/modules/${row.id}`);
  };

  return (
    <DataTable
      columns={fields}
      data={data.sort((a, b) => a.index - b.index)}
      onRowClick={onRowClick}
    />
  );
};

export default ModuleList;
