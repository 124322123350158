import API from "@aws-amplify/api";
import { Fab, Paper, Theme } from "@mui/material";
import * as React from "react";
import DataTable from "../../components/lists/DataTable";
import { listCourses, listProgramCourses } from "../../graphql/queries";
import AddIcon from "@mui/icons-material/Add";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { fetchAll } from "../../util/apiHelpers";

const fields = [
  { field: "name", headerName: "Name" },
  { field: "description", headerName: "Description" },
  { field: "coverImage", headerName: "Cover Image" },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "500px",
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

export interface CourseListProps {
  programId?: string;
}

const CourseList = (props: CourseListProps): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const history = useHistory();
  const { programId } = props;

  React.useEffect(() => {
    async function syncCourses() {
      if (programId) {
        setData(await fetchAll('listProgramCourses', {query: listProgramCourses}));
      } else {
        setData(await fetchAll('listCourses', {query: listCourses }));
      }
    }
    syncCourses();
  }, []);

  const onRowClick = (row) => {
    history.push(`/courses/${row.id}`);
  };

  const onFabClick = () => {
    history.push(`/courses/add`);
  };

  return (
    <div>
      <DataTable columns={fields} data={data} onRowClick={onRowClick} />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={onFabClick}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default CourseList;
