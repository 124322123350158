import * as React from "react";
import { hot } from "react-hot-loader";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  Theme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { BrowserRouter as Router } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import CoreStack from "./router/CoreStack";
import { Provider } from "react-redux";
import store from "./store";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

Amplify.configure(awsconfig);

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      backgroundColor: "rgb(234, 238, 243)",
    },
  }),
);

const App = () => {
  const classes = useStyles();
  const theme = createTheme();
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <Router>
              <CoreStack />
            </Router>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

declare let module: Record<string, unknown>;

export default hot(module)(App);
