/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminAddUserToGroup = /* GraphQL */ `
  mutation AdminAddUserToGroup($groupName: String, $username: String) {
    adminAddUserToGroup(groupName: $groupName, username: $username)
  }
`;
export const adminCreateUser = /* GraphQL */ `
  mutation AdminCreateUser(
    $email: String
    $firstName: String
    $lastName: String
  ) {
    adminCreateUser(email: $email, firstName: $firstName, lastName: $lastName) {
      Attributes {
        Name
        Value
      }
      Enabled
      MFAOptions {
        AttributeName
        DeliveryMedium
      }
      PreferredMfaSetting
      UserCreateDate
      UserLastModifiedDate
      UserMFASettingList
      UserStatus
      Username
    }
  }
`;
export const adminDeleteUser = /* GraphQL */ `
  mutation AdminDeleteUser($username: String) {
    adminDeleteUser(username: $username)
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $condition: ModelAnswerConditionInput
    $input: CreateAnswerInput!
  ) {
    createAnswer(condition: $condition, input: $input) {
      answer
      createdAt
      id
      moduleProgressId
      owner
      questionId
      updatedAt
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $condition: ModelCourseConditionInput
    $input: CreateCourseInput!
  ) {
    createCourse(condition: $condition, input: $input) {
      accessGroup
      coverImage
      createdAt
      description
      id
      modules {
        items {
          courseId
          coverImage
          createdAt
          delayNumber
          delayUOM
          description
          id
          index
          name
          updatedAt
          videoUrl
        }
        nextToken
      }
      name
      updatedAt
    }
  }
`;
export const createDownloadable = /* GraphQL */ `
  mutation CreateDownloadable(
    $condition: ModelDownloadableConditionInput
    $input: CreateDownloadableInput!
  ) {
    createDownloadable(condition: $condition, input: $input) {
      createdAt
      id
      index
      moduleId
      name
      owner
      updatedAt
      url
    }
  }
`;
export const createEnrollment = /* GraphQL */ `
  mutation CreateEnrollment(
    $condition: ModelEnrollmentConditionInput
    $input: CreateEnrollmentInput!
  ) {
    createEnrollment(condition: $condition, input: $input) {
      completedAt
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      enrolledAt
      id
      moduleSchedule {
        items {
          availableAt
          completedAt
          createdAt
          enrollmentId
          id
          moduleId
          owner
          startedAt
          updatedAt
        }
        nextToken
      }
      owner
      startedAt
      updatedAt
    }
  }
`;
export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient(
    $condition: ModelIngredientConditionInput
    $input: CreateIngredientInput!
  ) {
    createIngredient(condition: $condition, input: $input) {
      amount
      createdAt
      id
      product {
        createdAt
        id
        ingredients {
          nextToken
        }
        name
        namePlural
        updatedAt
      }
      productId
      recipe {
        createdAt
        date
        id
        imageUrl
        ingredients {
          nextToken
        }
        instructionVideoUrl
        name
        preparationMinutes
        state
        steps {
          nextToken
        }
        totalMinutes
        updatedAt
      }
      recipeId
      unit {
        createdAt
        id
        name
        sign
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createMeasurement = /* GraphQL */ `
  mutation CreateMeasurement(
    $condition: ModelMeasurementConditionInput
    $input: CreateMeasurementInput!
  ) {
    createMeasurement(condition: $condition, input: $input) {
      createdAt
      date
      id
      note
      owner
      type
      updatedAt
      weight
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership(
    $condition: ModelMembershipConditionInput
    $input: CreateMembershipInput!
  ) {
    createMembership(condition: $condition, input: $input) {
      cognitoId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const createModule = /* GraphQL */ `
  mutation CreateModule(
    $condition: ModelModuleConditionInput
    $input: CreateModuleInput!
  ) {
    createModule(condition: $condition, input: $input) {
      assignments {
        items {
          createdAt
          id
          index
          introduction
          moduleId
          question
          type
          updatedAt
        }
        nextToken
      }
      courseId
      coverImage
      createdAt
      delayNumber
      delayUOM
      description
      id
      index
      name
      tools {
        items {
          createdAt
          id
          index
          moduleId
          name
          owner
          updatedAt
          url
        }
        nextToken
      }
      updatedAt
      videoUrl
    }
  }
`;
export const createModuleProgress = /* GraphQL */ `
  mutation CreateModuleProgress(
    $condition: ModelModuleProgressConditionInput
    $input: CreateModuleProgressInput!
  ) {
    createModuleProgress(condition: $condition, input: $input) {
      availableAt
      completedAt
      createdAt
      enrollmentId
      id
      module {
        assignments {
          nextToken
        }
        courseId
        coverImage
        createdAt
        delayNumber
        delayUOM
        description
        id
        index
        name
        tools {
          nextToken
        }
        updatedAt
        videoUrl
      }
      moduleId
      owner
      startedAt
      updatedAt
      workbook {
        items {
          answer
          createdAt
          id
          moduleProgressId
          owner
          questionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption(
    $condition: ModelOptionConditionInput
    $input: CreateOptionInput!
  ) {
    createOption(condition: $condition, input: $input) {
      createdAt
      id
      index
      label
      questionId
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $condition: ModelProductConditionInput
    $input: CreateProductInput!
  ) {
    createProduct(condition: $condition, input: $input) {
      createdAt
      id
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      name
      namePlural
      updatedAt
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $condition: ModelProfileConditionInput
    $input: CreateProfileInput!
  ) {
    createProfile(condition: $condition, input: $input) {
      birthDate
      cognitoId
      createdAt
      currentCourse {
        items {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        nextToken
      }
      currentCourseId
      email
      firstName
      id
      lastName
      length
      targetWeight
      updatedAt
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $condition: ModelProgramConditionInput
    $input: CreateProgramInput!
  ) {
    createProgram(condition: $condition, input: $input) {
      courses {
        items {
          courseId
          createdAt
          id
          programId
          updatedAt
        }
        nextToken
      }
      createdAt
      description
      id
      imageUrl
      isForSale
      name
      price
      productStoreId
      productUrl
      updatedAt
    }
  }
`;
export const createProgramCourse = /* GraphQL */ `
  mutation CreateProgramCourse(
    $condition: ModelProgramCourseConditionInput
    $input: CreateProgramCourseInput!
  ) {
    createProgramCourse(condition: $condition, input: $input) {
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $condition: ModelQuestionConditionInput
    $input: CreateQuestionInput!
  ) {
    createQuestion(condition: $condition, input: $input) {
      answer {
        answer
        createdAt
        id
        moduleProgressId
        owner
        questionId
        updatedAt
      }
      createdAt
      id
      index
      introduction
      moduleId
      options {
        items {
          createdAt
          id
          index
          label
          questionId
          updatedAt
        }
        nextToken
      }
      question
      type
      updatedAt
    }
  }
`;
export const createRecipe = /* GraphQL */ `
  mutation CreateRecipe(
    $condition: ModelRecipeConditionInput
    $input: CreateRecipeInput!
  ) {
    createRecipe(condition: $condition, input: $input) {
      createdAt
      date
      id
      imageUrl
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      instructionVideoUrl
      name
      preparationMinutes
      state
      steps {
        items {
          createdAt
          id
          index
          instructionText
          recipeId
          updatedAt
        }
        nextToken
      }
      totalMinutes
      updatedAt
    }
  }
`;
export const createRecipeStep = /* GraphQL */ `
  mutation CreateRecipeStep(
    $condition: ModelRecipeStepConditionInput
    $input: CreateRecipeStepInput!
  ) {
    createRecipeStep(condition: $condition, input: $input) {
      createdAt
      id
      index
      instructionText
      recipeId
      updatedAt
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $condition: ModelUnitConditionInput
    $input: CreateUnitInput!
  ) {
    createUnit(condition: $condition, input: $input) {
      createdAt
      id
      name
      sign
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $condition: ModelAnswerConditionInput
    $input: DeleteAnswerInput!
  ) {
    deleteAnswer(condition: $condition, input: $input) {
      answer
      createdAt
      id
      moduleProgressId
      owner
      questionId
      updatedAt
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $condition: ModelCourseConditionInput
    $input: DeleteCourseInput!
  ) {
    deleteCourse(condition: $condition, input: $input) {
      accessGroup
      coverImage
      createdAt
      description
      id
      modules {
        items {
          courseId
          coverImage
          createdAt
          delayNumber
          delayUOM
          description
          id
          index
          name
          updatedAt
          videoUrl
        }
        nextToken
      }
      name
      updatedAt
    }
  }
`;
export const deleteDownloadable = /* GraphQL */ `
  mutation DeleteDownloadable(
    $condition: ModelDownloadableConditionInput
    $input: DeleteDownloadableInput!
  ) {
    deleteDownloadable(condition: $condition, input: $input) {
      createdAt
      id
      index
      moduleId
      name
      owner
      updatedAt
      url
    }
  }
`;
export const deleteEnrollment = /* GraphQL */ `
  mutation DeleteEnrollment(
    $condition: ModelEnrollmentConditionInput
    $input: DeleteEnrollmentInput!
  ) {
    deleteEnrollment(condition: $condition, input: $input) {
      completedAt
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      enrolledAt
      id
      moduleSchedule {
        items {
          availableAt
          completedAt
          createdAt
          enrollmentId
          id
          moduleId
          owner
          startedAt
          updatedAt
        }
        nextToken
      }
      owner
      startedAt
      updatedAt
    }
  }
`;
export const deleteIngredient = /* GraphQL */ `
  mutation DeleteIngredient(
    $condition: ModelIngredientConditionInput
    $input: DeleteIngredientInput!
  ) {
    deleteIngredient(condition: $condition, input: $input) {
      amount
      createdAt
      id
      product {
        createdAt
        id
        ingredients {
          nextToken
        }
        name
        namePlural
        updatedAt
      }
      productId
      recipe {
        createdAt
        date
        id
        imageUrl
        ingredients {
          nextToken
        }
        instructionVideoUrl
        name
        preparationMinutes
        state
        steps {
          nextToken
        }
        totalMinutes
        updatedAt
      }
      recipeId
      unit {
        createdAt
        id
        name
        sign
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMeasurement = /* GraphQL */ `
  mutation DeleteMeasurement(
    $condition: ModelMeasurementConditionInput
    $input: DeleteMeasurementInput!
  ) {
    deleteMeasurement(condition: $condition, input: $input) {
      createdAt
      date
      id
      note
      owner
      type
      updatedAt
      weight
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership(
    $condition: ModelMembershipConditionInput
    $input: DeleteMembershipInput!
  ) {
    deleteMembership(condition: $condition, input: $input) {
      cognitoId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const deleteModule = /* GraphQL */ `
  mutation DeleteModule(
    $condition: ModelModuleConditionInput
    $input: DeleteModuleInput!
  ) {
    deleteModule(condition: $condition, input: $input) {
      assignments {
        items {
          createdAt
          id
          index
          introduction
          moduleId
          question
          type
          updatedAt
        }
        nextToken
      }
      courseId
      coverImage
      createdAt
      delayNumber
      delayUOM
      description
      id
      index
      name
      tools {
        items {
          createdAt
          id
          index
          moduleId
          name
          owner
          updatedAt
          url
        }
        nextToken
      }
      updatedAt
      videoUrl
    }
  }
`;
export const deleteModuleProgress = /* GraphQL */ `
  mutation DeleteModuleProgress(
    $condition: ModelModuleProgressConditionInput
    $input: DeleteModuleProgressInput!
  ) {
    deleteModuleProgress(condition: $condition, input: $input) {
      availableAt
      completedAt
      createdAt
      enrollmentId
      id
      module {
        assignments {
          nextToken
        }
        courseId
        coverImage
        createdAt
        delayNumber
        delayUOM
        description
        id
        index
        name
        tools {
          nextToken
        }
        updatedAt
        videoUrl
      }
      moduleId
      owner
      startedAt
      updatedAt
      workbook {
        items {
          answer
          createdAt
          id
          moduleProgressId
          owner
          questionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption(
    $condition: ModelOptionConditionInput
    $input: DeleteOptionInput!
  ) {
    deleteOption(condition: $condition, input: $input) {
      createdAt
      id
      index
      label
      questionId
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $condition: ModelProductConditionInput
    $input: DeleteProductInput!
  ) {
    deleteProduct(condition: $condition, input: $input) {
      createdAt
      id
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      name
      namePlural
      updatedAt
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $condition: ModelProfileConditionInput
    $input: DeleteProfileInput!
  ) {
    deleteProfile(condition: $condition, input: $input) {
      birthDate
      cognitoId
      createdAt
      currentCourse {
        items {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        nextToken
      }
      currentCourseId
      email
      firstName
      id
      lastName
      length
      targetWeight
      updatedAt
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $condition: ModelProgramConditionInput
    $input: DeleteProgramInput!
  ) {
    deleteProgram(condition: $condition, input: $input) {
      courses {
        items {
          courseId
          createdAt
          id
          programId
          updatedAt
        }
        nextToken
      }
      createdAt
      description
      id
      imageUrl
      isForSale
      name
      price
      productStoreId
      productUrl
      updatedAt
    }
  }
`;
export const deleteProgramCourse = /* GraphQL */ `
  mutation DeleteProgramCourse(
    $condition: ModelProgramCourseConditionInput
    $input: DeleteProgramCourseInput!
  ) {
    deleteProgramCourse(condition: $condition, input: $input) {
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $condition: ModelQuestionConditionInput
    $input: DeleteQuestionInput!
  ) {
    deleteQuestion(condition: $condition, input: $input) {
      answer {
        answer
        createdAt
        id
        moduleProgressId
        owner
        questionId
        updatedAt
      }
      createdAt
      id
      index
      introduction
      moduleId
      options {
        items {
          createdAt
          id
          index
          label
          questionId
          updatedAt
        }
        nextToken
      }
      question
      type
      updatedAt
    }
  }
`;
export const deleteRecipe = /* GraphQL */ `
  mutation DeleteRecipe(
    $condition: ModelRecipeConditionInput
    $input: DeleteRecipeInput!
  ) {
    deleteRecipe(condition: $condition, input: $input) {
      createdAt
      date
      id
      imageUrl
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      instructionVideoUrl
      name
      preparationMinutes
      state
      steps {
        items {
          createdAt
          id
          index
          instructionText
          recipeId
          updatedAt
        }
        nextToken
      }
      totalMinutes
      updatedAt
    }
  }
`;
export const deleteRecipeStep = /* GraphQL */ `
  mutation DeleteRecipeStep(
    $condition: ModelRecipeStepConditionInput
    $input: DeleteRecipeStepInput!
  ) {
    deleteRecipeStep(condition: $condition, input: $input) {
      createdAt
      id
      index
      instructionText
      recipeId
      updatedAt
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $condition: ModelUnitConditionInput
    $input: DeleteUnitInput!
  ) {
    deleteUnit(condition: $condition, input: $input) {
      createdAt
      id
      name
      sign
      updatedAt
    }
  }
`;
export const generateCourseSchedule = /* GraphQL */ `
  mutation GenerateCourseSchedule(
    $courseId: String
    $enrollmentId: String
    $startDate: AWSDateTime
  ) {
    generateCourseSchedule(
      courseId: $courseId
      enrollmentId: $enrollmentId
      startDate: $startDate
    )
  }
`;
export const purchaseProgram = /* GraphQL */ `
  mutation PurchaseProgram($productId: String, $transactionId: String) {
    purchaseProgram(productId: $productId, transactionId: $transactionId)
  }
`;
export const subscribeUser = /* GraphQL */ `
  mutation SubscribeUser(
    $courseId: String
    $startDate: AWSDateTime
    $username: String
  ) {
    subscribeUser(
      courseId: $courseId
      startDate: $startDate
      username: $username
    )
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $condition: ModelAnswerConditionInput
    $input: UpdateAnswerInput!
  ) {
    updateAnswer(condition: $condition, input: $input) {
      answer
      createdAt
      id
      moduleProgressId
      owner
      questionId
      updatedAt
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $condition: ModelCourseConditionInput
    $input: UpdateCourseInput!
  ) {
    updateCourse(condition: $condition, input: $input) {
      accessGroup
      coverImage
      createdAt
      description
      id
      modules {
        items {
          courseId
          coverImage
          createdAt
          delayNumber
          delayUOM
          description
          id
          index
          name
          updatedAt
          videoUrl
        }
        nextToken
      }
      name
      updatedAt
    }
  }
`;
export const updateDownloadable = /* GraphQL */ `
  mutation UpdateDownloadable(
    $condition: ModelDownloadableConditionInput
    $input: UpdateDownloadableInput!
  ) {
    updateDownloadable(condition: $condition, input: $input) {
      createdAt
      id
      index
      moduleId
      name
      owner
      updatedAt
      url
    }
  }
`;
export const updateEnrollment = /* GraphQL */ `
  mutation UpdateEnrollment(
    $condition: ModelEnrollmentConditionInput
    $input: UpdateEnrollmentInput!
  ) {
    updateEnrollment(condition: $condition, input: $input) {
      completedAt
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      enrolledAt
      id
      moduleSchedule {
        items {
          availableAt
          completedAt
          createdAt
          enrollmentId
          id
          moduleId
          owner
          startedAt
          updatedAt
        }
        nextToken
      }
      owner
      startedAt
      updatedAt
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient(
    $condition: ModelIngredientConditionInput
    $input: UpdateIngredientInput!
  ) {
    updateIngredient(condition: $condition, input: $input) {
      amount
      createdAt
      id
      product {
        createdAt
        id
        ingredients {
          nextToken
        }
        name
        namePlural
        updatedAt
      }
      productId
      recipe {
        createdAt
        date
        id
        imageUrl
        ingredients {
          nextToken
        }
        instructionVideoUrl
        name
        preparationMinutes
        state
        steps {
          nextToken
        }
        totalMinutes
        updatedAt
      }
      recipeId
      unit {
        createdAt
        id
        name
        sign
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMeasurement = /* GraphQL */ `
  mutation UpdateMeasurement(
    $condition: ModelMeasurementConditionInput
    $input: UpdateMeasurementInput!
  ) {
    updateMeasurement(condition: $condition, input: $input) {
      createdAt
      date
      id
      note
      owner
      type
      updatedAt
      weight
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $condition: ModelMembershipConditionInput
    $input: UpdateMembershipInput!
  ) {
    updateMembership(condition: $condition, input: $input) {
      cognitoId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const updateModule = /* GraphQL */ `
  mutation UpdateModule(
    $condition: ModelModuleConditionInput
    $input: UpdateModuleInput!
  ) {
    updateModule(condition: $condition, input: $input) {
      assignments {
        items {
          createdAt
          id
          index
          introduction
          moduleId
          question
          type
          updatedAt
        }
        nextToken
      }
      courseId
      coverImage
      createdAt
      delayNumber
      delayUOM
      description
      id
      index
      name
      tools {
        items {
          createdAt
          id
          index
          moduleId
          name
          owner
          updatedAt
          url
        }
        nextToken
      }
      updatedAt
      videoUrl
    }
  }
`;
export const updateModuleProgress = /* GraphQL */ `
  mutation UpdateModuleProgress(
    $condition: ModelModuleProgressConditionInput
    $input: UpdateModuleProgressInput!
  ) {
    updateModuleProgress(condition: $condition, input: $input) {
      availableAt
      completedAt
      createdAt
      enrollmentId
      id
      module {
        assignments {
          nextToken
        }
        courseId
        coverImage
        createdAt
        delayNumber
        delayUOM
        description
        id
        index
        name
        tools {
          nextToken
        }
        updatedAt
        videoUrl
      }
      moduleId
      owner
      startedAt
      updatedAt
      workbook {
        items {
          answer
          createdAt
          id
          moduleProgressId
          owner
          questionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption(
    $condition: ModelOptionConditionInput
    $input: UpdateOptionInput!
  ) {
    updateOption(condition: $condition, input: $input) {
      createdAt
      id
      index
      label
      questionId
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $condition: ModelProductConditionInput
    $input: UpdateProductInput!
  ) {
    updateProduct(condition: $condition, input: $input) {
      createdAt
      id
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      name
      namePlural
      updatedAt
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $condition: ModelProfileConditionInput
    $input: UpdateProfileInput!
  ) {
    updateProfile(condition: $condition, input: $input) {
      birthDate
      cognitoId
      createdAt
      currentCourse {
        items {
          accessGroup
          coverImage
          createdAt
          description
          id
          name
          updatedAt
        }
        nextToken
      }
      currentCourseId
      email
      firstName
      id
      lastName
      length
      targetWeight
      updatedAt
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $condition: ModelProgramConditionInput
    $input: UpdateProgramInput!
  ) {
    updateProgram(condition: $condition, input: $input) {
      courses {
        items {
          courseId
          createdAt
          id
          programId
          updatedAt
        }
        nextToken
      }
      createdAt
      description
      id
      imageUrl
      isForSale
      name
      price
      productStoreId
      productUrl
      updatedAt
    }
  }
`;
export const updateProgramCourse = /* GraphQL */ `
  mutation UpdateProgramCourse(
    $condition: ModelProgramCourseConditionInput
    $input: UpdateProgramCourseInput!
  ) {
    updateProgramCourse(condition: $condition, input: $input) {
      course {
        accessGroup
        coverImage
        createdAt
        description
        id
        modules {
          nextToken
        }
        name
        updatedAt
      }
      courseId
      createdAt
      id
      program {
        courses {
          nextToken
        }
        createdAt
        description
        id
        imageUrl
        isForSale
        name
        price
        productStoreId
        productUrl
        updatedAt
      }
      programId
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $condition: ModelQuestionConditionInput
    $input: UpdateQuestionInput!
  ) {
    updateQuestion(condition: $condition, input: $input) {
      answer {
        answer
        createdAt
        id
        moduleProgressId
        owner
        questionId
        updatedAt
      }
      createdAt
      id
      index
      introduction
      moduleId
      options {
        items {
          createdAt
          id
          index
          label
          questionId
          updatedAt
        }
        nextToken
      }
      question
      type
      updatedAt
    }
  }
`;
export const updateRecipe = /* GraphQL */ `
  mutation UpdateRecipe(
    $condition: ModelRecipeConditionInput
    $input: UpdateRecipeInput!
  ) {
    updateRecipe(condition: $condition, input: $input) {
      createdAt
      date
      id
      imageUrl
      ingredients {
        items {
          amount
          createdAt
          id
          productId
          recipeId
          updatedAt
        }
        nextToken
      }
      instructionVideoUrl
      name
      preparationMinutes
      state
      steps {
        items {
          createdAt
          id
          index
          instructionText
          recipeId
          updatedAt
        }
        nextToken
      }
      totalMinutes
      updatedAt
    }
  }
`;
export const updateRecipeStep = /* GraphQL */ `
  mutation UpdateRecipeStep(
    $condition: ModelRecipeStepConditionInput
    $input: UpdateRecipeStepInput!
  ) {
    updateRecipeStep(condition: $condition, input: $input) {
      createdAt
      id
      index
      instructionText
      recipeId
      updatedAt
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $condition: ModelUnitConditionInput
    $input: UpdateUnitInput!
  ) {
    updateUnit(condition: $condition, input: $input) {
      createdAt
      id
      name
      sign
      updatedAt
    }
  }
`;
