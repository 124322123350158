import API from "@aws-amplify/api";
import { Grid, Paper, Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";
import { useHistory, useParams } from "react-router";
import Single, { DataField } from "../../../components/Single";
import { getModule } from "../../../graphql/queries";
import EditIcon from "@mui/icons-material/Edit";
import QuestionList from "../question/QuestionList";
import { deleteModule } from "../../../graphql/mutations";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import Header from "../../../components/Header";
import Image from "../../../components/Image";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import DownloadableList from "../downloadable/DownloadableList";

const fields: DataField[] = [
  { key: "name", label: "Name" },
  { key: "index", label: "Module sort order" },
  { key: "description", label: "Description" },
  { key: "coverImage", label: "Cover image", renderFunction: (value) => <Image src={value} /> },
  { key: "videoUrl", label: "Video URL" },
  { key: "delayNumber", label: "Wait after previous" },
  { key: "delayUOM", label: "Wait unit of measure" },
];

interface ParamsType {
  courseId: string;
  moduleId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(5),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const ModuleSingle = (): JSX.Element => {
  const [module, setModule] = React.useState<any>({});
  const [isOpen, setOpen] = React.useState(false);
  const { courseId, moduleId } = useParams<ParamsType>();
  const history = useHistory();
  const classes = useStyles();

  React.useEffect(() => {
    async function syncModule() {
      const module = await API.graphql({
        query: getModule,
        variables: { id: moduleId },
      });
      setModule((module as any).data.getModule);
    }
    syncModule();
  }, [moduleId]);

  const onEditClick = () => {
    history.push(`/courses/${courseId}/modules/${moduleId}/edit`);
  };

  const handleDelete = async () => {
    await API.graphql({
      query: deleteModule,
      variables: { input: { id: moduleId } },
    });
    history.push(`/courses/${courseId}`);
  };

  const onDeleteClick = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Header
          title="Module"
          actions={[
            {
              name: "Add question",
              icon: <Add />,
              onClick: () =>
                history.push(
                  `/courses/${courseId}/modules/${moduleId}/questions/add`,
                ),
            },
            {
              name: "Add tool",
              icon: <Add />,
              onClick: () => history.push(`/courses/${courseId}/modules/${moduleId}/tools/add`)
            },
            {
              name: "Edit module",
              icon: <EditIcon />,
              onClick: onEditClick,
            },
            {
              name: "Delete module",
              icon: <Delete />,
              onClick: onDeleteClick,
            },
          ]}
        />
        <Single data={module} fields={fields} />
      </Paper>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h4">Questions</Typography>
          <QuestionList moduleId={moduleId} courseId={courseId} />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4">Tools</Typography>
          <DownloadableList moduleId={moduleId} courseId={courseId} />
        </Grid>
      </Grid>
      <ConfirmationDialog
        title="Are you sure"
        content="You're about to delete a course, this cannot be undone. Are you sure?"
        onConfirm={handleDelete}
        open={isOpen}
        onReject={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default ModuleSingle;
