import * as React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { isAuthenticatedSelector } from "../store/auth/selectors";

type PrivateRouteProps = {
  component: React.ComponentType<any>;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: C, ...rest } = props;

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (isAuthenticated) {
          return <C {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
